/* SCAFFOLDING */
// Links
a {
    color: $link-color;

    &:hover:not(.btn),
    &:focus:not(.btn) {
        color: $link-hover-color;
        text-decoration: underline;
    }
}
