@import '~fcac/scss/shared/shared';
$price-container-bg-color: #e5f1f9;
$column-border-color: #999;
$price-border-color: #dcdcdc;

.product-results-columns {
    .column {
        border: 1px solid $column-border-color;
    }

    .title {
        @include font-title;

        color: $text-color;
        font-size: 30px;
        padding: 0 15px;
    }

    .price-container {
        background-color: $price-container-bg-color;
    }

    .label {
        @include font-body-bold;

        color: #666;
        font-size: 13px;
        text-transform: uppercase;
    }

    .price {
        @include font-title;

        color: $brand-color-red;
        font-size: 80px;

        span {
            font-size: 24px;
        }

        @include media(bp-lt-lg) {
            font-size: 64px;

            span {
                font-size: 20px;
            }
        }
    }

    .regular-price {
        @include font-title;

        color: $text-color;
        font-size: 16px;
    }

    .copy-container {
        li {
            color: $text-color;
            font-size: 16px;
        }
    }

    .disclaimer {
        color: $text-color;
        font-size: 12px;
        line-height: 1.1em;
    }

    @include media(bp-lt-md) {
        .column {
            border: none;
        }

        .title {
            font-size: 24px;
        }

        .price-container {
            border-top: 1px solid $price-border-color;
            border-bottom: 1px solid $price-border-color;
        }

        .regular-price {
            font-size: 14px;
        }

        .copy-container {
            font-size: 18px;
        }
    }

    max-width: 1250px;
}
