/* guide to breakpoints

    <------XS------|a|------SM------|b|------MD------|c|------LG------|d|------XL------|e|----XXL----->
    <----LT-SM-----|a|
    <----LT-MD----------------------|b|
    <----LT-LG---------------------------------------|c|
    <----LT-XL--------------------------------------------------------|d|
    <----LT-XXL------------------------------------------------------------------------|e|
                   |a|------GT-XS--------------------------------------------------------------------->
                                    |b|------GT-SM---------------------------------------------------->
                                                     |c|------GT-MD----------------------------------->
                                                                      |d|------GT-LG------------------>
                                                                                       |e|---GT-XL---->
*/
.font-body {
  font-family: "Avenir", Arial, sans-serif !important;
  font-size: 16px;
  line-height: 110%;
}

.intro-article .title,
.intro-article .subtitle {
  font-family: "Ultramagnetic", Arial, sans-serif !important;
}

.articles a {
  font-family: "Ultramagnetic", Arial, sans-serif !important;
  color: #333;
  text-decoration: none;
}
.articles a span:last-child {
  color: #d81e05;
}
.articles a:hover {
  color: #d81e05;
}
.articles .summary p {
  font-size: 14px;
}
@media only screen and (max-width: 539px) {
  .articles {
    padding-bottom: 0 !important;
  }
  .articles .intro-cta a {
    background-color: #666;
  }
  .articles .intro-cta span {
    display: none;
  }
}

.slide-content p {
  font-size: 14px;
}

.article-body p img {
  padding-left: 25px;
}

.articles.reskin {
  background: none;
  padding: 40px 0;
}
.articles.reskin h2,
.articles.reskin h3 {
  margin: 5px;
  text-align: center;
}
.articles.reskin h3.subtitle {
  font-family: "Avenir", Arial, sans-serif !important;
  font-size: 20px;
  padding: 0 15%;
}
@media only screen and (max-width: 539px) {
  .articles.reskin h2 {
    font-size: 28px;
    font-size: 7vw;
  }
}
.articles.reskin .col {
  width: 33%;
  border-right: none;
}
@media only screen and (max-width: 539px) {
  .articles.reskin .col:last-child {
    display: block;
  }
}
.articles.reskin .articles-wrap {
  margin: 30px 0;
  overflow: initial;
}
@media only screen and (max-width: 1024px) {
  .articles.reskin .articles-wrap {
    margin: 30px 0 60px;
  }
}
@media only screen and (max-width: 539px) {
  .articles.reskin .articles-wrap {
    margin: 20px 0 60px;
  }
}
.articles.reskin .teaser .image {
  height: auto;
  max-height: none;
}
.articles.reskin .teaser .image img {
  height: auto;
  max-width: none;
  width: 100%;
}
@media only screen and (max-width: 539px) {
  .articles.reskin .teaser .image {
    height: auto;
    max-height: 55vw;
  }
}
.articles.reskin .teaser .title {
  font-size: 18px;
}
@media only screen and (max-width: 539px) {
  .articles.reskin .teaser .title {
    font-size: 14px;
    text-align: center;
    width: 100%;
  }
}

.articles-carousel .cta-link {
  font-family: "Ultramagnetic", Arial, sans-serif !important;
  font-size: 18px;
  color: #4a4a4a;
  text-decoration: none;
}
.articles-carousel .cta-link:after {
  content: ">";
  margin-left: 6px;
  color: #d81e05;
}
.articles-carousel .cta-link:hover {
  text-decoration: none;
  color: #d81e05;
}
@media only screen and (max-width: 767px) {
  .articles-carousel .cta-link {
    font-size: 16px;
  }
}
.articles-carousel .swiper-slide-active .slide-content,
.articles-carousel .swiper-slide-next .slide-content {
  border-right: 1px solid #dcdcdc;
}
@media only screen and (max-width: 1024px) {
  .articles-carousel .swiper-slide-active .slide-content,
  .articles-carousel .swiper-slide-next .slide-content {
    border-right: 0;
  }
}
.articles-carousel .title {
  font-family: "Ultramagnetic", Arial, sans-serif !important;
  font-size: 42px;
  color: #4a4a4a;
  font-weight: normal;
}
.articles-carousel .slide-title {
  font-family: "Ultramagnetic", Arial, sans-serif !important;
  font-size: 24px;
  color: #4a4a4a;
  font-weight: normal;
}
.articles-carousel .slide-title a {
  text-decoration: none;
  color: #4a4a4a;
}
.articles-carousel .slide-title a:hover {
  text-decoration: none !important;
  color: #4a4a4a !important;
}
@media only screen and (max-width: 767px) {
  .articles-carousel .title {
    font-size: 30px;
  }
  .articles-carousel .slide-title {
    font-family: "Ultramagnetic", Arial, sans-serif !important;
    font-size: 24px;
    color: #4a4a4a;
  }
}