$overview-border-color: #dcdcdc;
$internet-price-label-color: #666;

.overview-price-item {
    @include cta-link(16px);

    .description {
        border-right: 1px solid $overview-border-color;

        .btn {
            @include media(bp-lt-sm) {
                margin-top: 10px;
            }
        }
    }

    .internet-price {
        .label {
            font-size: 12px;
            color: $internet-price-label-color;
        }

        .price {
            @include font-title;
            font-size: 60px;
            color: $brand-color-red;

            span {
                vertical-align: top;
                font-size: 20px;
            }
        }
    }

    .folder {
        @include font-title;
        font-size: 18px;

        &:after,
        &.active:after {
            @include font-body;
            font-size: 24px;
        }

        &.active:after {
            margin-top: -1px;
            font-size: 22px;
        }
    }

    @include media(bp-lt-lg) {
        .internet-price {
            .label {
                font-size: 11px;
            }

            .price {
                font-size: 40px;

                span {
                    font-size: 18px;
                }
            }
        }
    }

    @include media(bp-lt-md) {
        .description {
            border-right: none;
            border-bottom: 1px solid $overview-border-color;
        }

        .internet-price {
            border-bottom: 1px solid $overview-border-color;

            .label {
                font-size: 13px;
            }

            .price {
                font-size: 80px;

                span {
                    font-size: 24px;
                }
            }
        }
    }
}
