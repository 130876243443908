@import '~common/scss/shared/shared';

$color-red: #d31a0b;
$color-black: $black;
$color-white: $white;

.jump-links {
    min-height: 55px;
    background-color: $color-red;
    display: flex;
    justify-content: flex-start;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 999;
    @include media(bp-gt-sm) {
        justify-content: center;
    }
    .jump-links-wrapper {
        display: flex;
        .buttons-container {
            display: flex;
            .buttons {
                display: flex;
                max-width: 100vw;
                overflow: hidden;
                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    display: flex;
                    flex-direction: row;
                    gap: 15px;
                    padding-left: 20px;
                    overflow-x: auto;
                    @include media(bp-gt-sm) {
                        gap: 24px;
                        padding-left: 0;
                        justify-content: center;
                    }
                    li {
                        display: flex;
                        align-items: center;
                        a {
                            background-color: transparent;
                            display: block;
                            white-space: nowrap;
                            &.button {
                                span {
                                    @include font-title;
                                    color: $color-white;
                                    font-size: 20px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 20px;
                                    letter-spacing: 1px;
                                    text-transform: uppercase;
                                    padding: 5px 10px;
                                    display: inline-block;
                                    @include media(bp-gt-sm) {
                                    }
                                }
                            }
                            &.is-active {
                                background-color: $color-white;
                                border-radius: 15px;
                                span {
                                    color: $color-black;
                                }
                            }
                        }
                        &:first-child {
                            @include media(bp-gt-sm) {
                                margin-left: 24px;
                            }
                        }
                        &:last-child {
                            @include media(bp-gt-sm) {
                                margin-right: 24px;
                            }
                        }
                    }
                }
            }
        }
    }
}
