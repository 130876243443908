@import '~fcac/scss/shared/shared';

.title-text-cta {
    p {
        font-size: 16px;
    }
}

.title-text-cta.body-basefontsize {
    p {
        font-size: $font-size-base;
    }
}
