.wait {
    .coral-Wait {
        width: 52px;
        height: 56px;

        background-image: svg-url(
            '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="71.288px" height="76px" viewBox="0 0 71.288 76" enable-background="new 0 0 71.288 76" xml:space="preserve"><path fill="#EE3124" d="M35.722,71.934C-0.299,50.325,3.75,12.02,3.75,12.02c34.453-18.902,63.964,0.159,63.964,0.159C69.64,54.226,35.722,71.934,35.722,71.934 M71.086,9.985c0,0-32.206-22.467-70.833,0c0,0-5.561,43.011,35.416,66.015C35.669,76,74.403,57.491,71.086,9.985"/><path fill="#EE3124" d="M37.473,25.072c0,0-2.247,5.778-0.43,9.095c0,0,6.421-3.853,12.841-1.606l-3.853,9.63c0,0-3.104-2.247-6.205-0.322c0,0,2.462,7.704-3.103,13.481c-5.564,5.778-10.914,5.458-12.626,4.924c0,0,2.354-3.853,2.568-7.384c0.215-3.53-0.749-6.206-0.749-6.206s-1.713-3.639-1.927-9.092c-0.214-5.459,3.317-12.091,4.494-13.482c0,0-5.885-1.714-14.338,3.85c0,0-1.497-6.632,4.281-12.946c5.778-6.312,14.231-4.172,14.231-4.172l9.63,2.033c0,0,6.441,1.21,8.986,0.214c2.458-0.964,4.6-2.354,5.777-3.318c0,0,0.785,4.174-0.356,7.454c-0.885,2.541-2.587,4.537-5.955,6.456C46.888,25.875,41.859,25.928,37.473,25.072"/></svg>'
        );

        &.coral-Wait--center {
            margin-top: -6rem;
            margin-left: -26px;
        }
    }

    .message {
        color: $brand-color-red;
    }
}
