.offers {
    .heading {
        @include font-title;
        font-size: 32px;
    }

    .offers-signup {
        padding: 20px 0;

        @include media(bp-lt-sm) {
            padding: 0 0 20px;

            .title {
                @include font-title;
                font-size: 26px !important;
                color: $black;
            }
        }

        .title {
            @include font-title;
            font-size: 18px;
            line-height: 1.8;

            @include media(bp-lt-lg) {
                font-size: 16px;
            }

            @include media(bp-lt-sm) {
                font-size: 18px;
            }
        }

        .btn {
            border: 0;
            padding: 5px 9px;
            line-height: 1;
            vertical-align: middle;
            background-color: $dark-gray;
        }

        .ctas {
            line-height: 2.2;
        }
    }

    .offer {
        background: #fafafa;

        .title {
            @include font-title;
            text-transform: uppercase;
            font-size: 26px;
            line-height: 1;

            @include media(bp-lt-lg) {
                font-size: 20px;
            }
        }

        .expiration {
            @include font-body-bold;
            font-size: 12px;
        }

        .coupon-cta,
        .coupon-text-cta {
            .btn {
                padding: 8px 12px;
                font-size: 18px;
            }
        }
    }

    .offers-area {
        .heading {
            font-size: 26px;
            margin-bottom: 0;

            @include media(bp-lt-sm) {
                margin-bottom: 15px;
            }
        }

        .desc {
            margin: 2px 0 20px;
            @include media(bp-lt-sm) {
                display: none;
            }
        }

        @include media(bp-lt-lg) {
            .btn {
                font-size: 15px;
            }
        }
    }

    .offers-more {
        .btn {
            background-color: transparent;
            color: $dark-gray;
            padding: 0;
            border: 0;

            &:after {
                content: '\003e';
                color: $brand-color-red;
                margin-left: 7px;
            }
        }
    }
}
