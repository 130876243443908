@import '~fcac/scss/shared/shared';

.dotted-space {
    background:
        linear-gradient(90deg, #e2231a 17px, transparent 1%) center,
        linear-gradient(#e2231a 17px, transparent 1%) center,
        $white;
    background-size: 22px 22px;
}

.dotted-tight {
    background:
        linear-gradient(90deg, #e2231a 9px, transparent 1%) center,
        linear-gradient(#e2231a 9px, transparent 1%) center,
        $white;
    background-size: 12px 12px;
}

.diagonal,
.diagonal-lg {
    background-image: repeating-linear-gradient(-135deg, #e2231a, #e2231a 6px, $white 0px, $white 10px, #e2231a 0px);
}
