.overview-basic-item {
    .item-supertitle {
        @include font-title;
        font-size: 16px;
        color: $overview-supertitle-color;
    }

    @include media(bp-lt-md) {
        .item-supertitle {
            font-size: 18px;
        }
    }
}
