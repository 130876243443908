@import '~fcac/scss/shared/shared';

.speed-rating {
    .item {
        border: 1px solid #dcdcdb;
        text-transform: uppercase;
    }

    .type,
    .unit {
        color: $text-color;
    }

    .type {
        font-weight: bold;
        font-size: 18px;
    }

    .speed {
        @include font-title;

        color: $brand-color-red;
        font-size: 30px;
    }

    .unit {
        font-size: 14px;
    }

    @include media(bp-lt-lg) {
        .type {
            font-size: 14px;
        }

        .speed {
            font-size: 16px;
        }

        .unit {
            font-size: 9px;
            font-weight: bold;
        }
    }

    @include media(bp-lt-md) {
        .title {
            font-size: 30px;
        }

        .label {
            font-size: 12px;
        }
    }
}
