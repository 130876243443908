$default-button-background-color: $brand-color-red;
$default-button-text-color: $white;
$gray-button-color: #666;
$secondary-button-background-color: $white;
$secondary-button-v2-background-color: #d6d6d6;

.btn,
.experiencefragment button {
    @include font-title;

    border: 2px solid $default-button-background-color;
    background-color: $default-button-background-color;
    color: $default-button-text-color;
    font-size: 19px;
    font-weight: normal;
    padding: 8px 11px;

    @include media(bp-lt-md) {
        width: 100%;
    }

    &:hover,
    &:active {
        border: 2px solid darken($default-button-background-color, 10%);
        background-color: darken($default-button-background-color, 10%);
        color: $default-button-text-color;
    }

    &[disabled] {
        background: $default-button-background-color !important;
        border: 2px solid #6f3f3f !important;

        &:hover {
            background: $default-button-background-color !important;
        }
    }

    &.secondary {
        background-color: $secondary-button-background-color;
        border: 2px solid $brand-color-red;
        color: $brand-color-red;
        text-decoration: none;
        &:hover,
        &:active {
            background-color: darken($secondary-button-background-color, 10%);
        }
        &[disabled] {
            background-color: #ccc !important;
            border-color: lighten($gray-button-color, 15%) !important;
            color: lighten($gray-button-color, 15%);
        }
    }

    &.btn-gray {
        background-color: $secondary-button-background-color;
    }
    &.btn-gray:hover,
    &.btn-gray:active {
        background-color: darken($secondary-button-background-color, 10%);
    }
    // This css is use for new offers refresh cards popup button and also use in offers detail page button, purpose for add this css is that other button css is overriding.
    &--v2 {
        border-radius: 4px;
        font-size: 15px !important;
        padding: 13px 30px !important;
        margin: 0;
        min-width: 168px;
        border: 2px solid !important;

        &-secondary {
            border: 2px solid $secondary-button-v2-background-color !important;
            background-color: $white !important;
            color: $black !important;

            &:hover {
                background-color: $secondary-button-v2-background-color !important;
                color: $black !important;
                border-color: $secondary-button-v2-background-color !important;
            }
        }
    }
}

.cta-link {
    @include font-title;

    font-size: 18px;
    color: black;
    text-decoration: none;

    &:after {
        content: '>';
        margin-left: 6px;
        color: $brand-color-red;
    }

    &:hover {
        text-decoration: none;
        color: $brand-color-red;
    }

    @include media(bp-lt-md) {
        font-size: 16px;
    }
}

@mixin fcac_button() {
    @extend .btn;
}

@mixin fcac_cta-link() {
    @extend .cta-link;
}
