// .default is used to show original styles and .no-styles is an option to hide styles
.consumer-rights-form-container:not(.default):not(.no-styles) {
    .consumer-rights-state {
        .consumer-rights-state-next_button {
            @include font-body-bold;
            background-color: $brand-color-red;
            border-color: $brand-color-red;
        }
    }

    .consumer-rights-form-section {
        .consumer-rights-form {
            .consumer-rights-form-submit_button {
                @include font-body-bold;
                background-color: $brand-color-red;
                border-color: $brand-color-red;
            }
        }

        .validate-errors-list {
            color: $brand-color-red;
        }
    }

    .consumer-rights-form-message-section,
    .consumer-rights-privacy-section {
        .error-message__text {
            color: $brand-color-red;
        }
    }
}

// provided to us. not used.
.consumer-rights-form-container.default {
    .consumer-rights-state {
        .consumer-rights-state-next_button {
            background-color: $brand-color-red;
            border-color: $brand-color-red;
        }
    }

    .consumer-rights-form-section {
        .consumer-rights-form {
            .consumer-rights-form-submit_button {
                background-color: $brand-color-red;
                border-color: $brand-color-red;
            }
        }

        .validate-errors-list {
            color: $brand-color-red;
        }
    }

    .consumer-rights-form-message-section,
    .consumer-rights-privacy-section {
        .error-message__text {
            color: $brand-color-red;
        }
    }
}