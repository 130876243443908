@import '~fcac/scss/shared/shared';

.brand-specs {
    .title {
        @include font-title;

        color: $text-color;
        font-size: 42px;
    }

    .copy-container {
        color: $text-color;
    }

    ol {
        list-style: none;
        counter-reset: li;
    }

    li {
        &:before {
            @include font-title;
            font-size: 18px;
            background-color: $brand-color-red;
        }

        span {
            @include font-title;
            font-size: 18px;
            text-transform: uppercase;
        }
    }

    .speed-rating {
        .title {
            font-size: 32px;
        }
    }
}
