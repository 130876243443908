.appt-step {
    dt {
        text-transform: uppercase;
    }

    .heading {
        @include media(bp-lt-lg) {
            font-size: 30px;
        }

        @include media(bp-lt-sm) {
            font-size: 25px;
        }
    }

    .wrap {
        background: linear-gradient(to top, #eee, transparent 150px);
        background: -webkit-linear-gradient(to top, #eee, transparent 150px);
        border: solid 1px $appt-step-border-color-light;

        @include media(bp-lt-sm) {
            background: none;
            border: none;
        }

        @media print {
            background: none;
        }
    }
}
