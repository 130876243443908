@import '~fcac/scss/shared/shared';

.breadcrumbs {
    font-size: 12px;
    color: $text-color;

    a {
        color: $brand-color-red;

        &:hover {
            color: $brand-color-red;
        }
    }

    span {
        color: $text-color;

        &.delim:before {
            color: $text-color;
        }
    }
}
