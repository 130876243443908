/* guide to breakpoints

    <------XS------|a|------SM------|b|------MD------|c|------LG------|d|------XL------|e|----XXL----->
    <----LT-SM-----|a|
    <----LT-MD----------------------|b|
    <----LT-LG---------------------------------------|c|
    <----LT-XL--------------------------------------------------------|d|
    <----LT-XXL------------------------------------------------------------------------|e|
                   |a|------GT-XS--------------------------------------------------------------------->
                                    |b|------GT-SM---------------------------------------------------->
                                                     |c|------GT-MD----------------------------------->
                                                                      |d|------GT-LG------------------>
                                                                                       |e|---GT-XL---->
*/
.font-body {
  font-family: "Avenir", Arial, sans-serif !important;
  font-size: 16px;
  line-height: 110%;
}

.appt-step dt {
  text-transform: uppercase;
}
@media only screen and (max-width: 1024px) {
  .appt-step .heading {
    font-size: 30px;
  }
}
@media only screen and (max-width: 539px) {
  .appt-step .heading {
    font-size: 25px;
  }
}
.appt-step .wrap {
  background: linear-gradient(to top, #eee, transparent 150px);
  background: -webkit-linear-gradient(to top, #eee, transparent 150px);
  border: solid 1px #dcdcdb;
}
@media only screen and (max-width: 539px) {
  .appt-step .wrap {
    background: none;
    border: none;
  }
}
@media print {
  .appt-step .wrap {
    background: none;
  }
}

.appt-step-1 .error {
  color: #d81e05;
}
.appt-step-1 .map {
  border: solid 1px #aaa;
}
.appt-step-1 input {
  padding: 8px;
  width: 42%;
}
.appt-step-1 .search-block-mobile {
  background: linear-gradient(to bottom, #eee, transparent 50px);
}

.appt-step-1-results {
  border: solid 1px #aaa;
}
@media only screen and (max-width: 539px) {
  .appt-step-1-results {
    border: none;
  }
}
@media only screen and (max-width: 539px) {
  .appt-step-1-results .result {
    background: linear-gradient(to bottom, #eee, transparent 50px) !important;
  }
}
.appt-step-1-results .result:nth-child(odd) {
  background: #e5f1f9;
}
@media only screen and (max-width: 539px) {
  .appt-step-1-results .result:nth-child(odd) {
    background: none;
  }
}
.appt-step-1-results .result .ctas-mobile a {
  font-family: "Ultramagnetic", Arial, sans-serif !important;
  text-transform: uppercase;
  text-decoration: none;
}
.appt-step-1-results .result .ctas-mobile .label {
  font-size: 12px;
}
.appt-step-1-results .result .open-nights {
  font-family: "Ultramagnetic", Arial, sans-serif !important;
  font-size: 16px;
}
.appt-step-1-results .result .holiday {
  color: #d81e05;
}

@media only screen and (max-width: 539px) {
  .widemap .appt-step-1-results .result {
    background: none !important;
  }
}