@import '~fcac/scss/shared/shared';

.heading-with-background-image {
    .image-wrap {
        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: linear-gradient(to top, $black, transparent 150px);
        }
    }

    .heading,
    .subhead {
        @include font-title;
        line-height: 1.2;
    }

    .subhead {
        letter-spacing: 1px;
    }
}
