@import '~fcac/scss/shared/shared';

.horizontal-vehicle-filter {
    .heading,
    .subhead {
        @include font-title;
        text-transform: uppercase;
        color: $text-color;
    }

    .heading {
        font-size: 42px;

        @include media(bp-lt-sm) {
            font-size: 30px;
        }
    }

    .subhead {
        font-size: 20px;

        @include media(bp-lt-sm) {
            font-size: 15px;
        }
    }
}
