@import '~common/scss/shared/shared';

.ecomm-cart-icon {
    position: relative;
    display: none;
    justify-content: center;
    align-items: center;
    width: 74px;
    height: 90px;
    cursor: pointer;
    user-select: none;
    top: 0;
    border: none;
    background: transparent;

    @include media(bp-lt-nav) {
        position: absolute;
        top: 0;
        right: 65px; //55px;
        width: 55px;
        height: 60px;
        margin-top: 1px;
    }

    .icon-cart {
        fill: #6e6e6e;
    }

    .counter {
        position: absolute;
        top: 28px;
        right: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 15px;
        height: 15px;
        font-size: 10px;
        font-weight: 700;
        background-color: $brand-color-red;
        color: $white;
        border-radius: 10px;

        @include media(bp-lt-nav) {
            top: 12px;
            right: 7px;
        }
    }

    span {
        &.ecomm-cart-text {
            display: none;
        }
    }

    &.disabled {
        cursor: default;
    }
}

.top-nav ~ div.wait {
    display: none;
}
