@import '~fcac/scss/shared/shared';

.product-carousel {
    @include cta-link();

    .title {
        @include font-title;

        font-size: 42px;
        text-transform: uppercase;
        color: $text-color;
        font-weight: normal;
    }

    .subtitle {
        @include font-title;

        font-size: 24px;
        color: $text-color;
        text-transform: uppercase;
    }

    .product-category {
        @include font-title;

        margin: 1em 0 0;
        line-height: 1.2em;
        font-size: 18px;
        color: $text-color;
    }

    .product-name {
        @include font-title;

        margin: 0 0 1em;
        line-height: 1.2em;
        font-size: 18px;
        color: $brand-color-red;
    }

    p {
        font-size: 16px;
        color: $text-color;
        line-height: 1.2em;
    }

    @include media(bp-lt-md) {
        .carousel {
            width: 100%;
        }

        .title {
            font-size: 30px;
            line-height: 1em;
        }

        .subtitle {
            font-size: 14px;
        }

        .product-category,
        .product-name,
        p {
            font-size: 15px;
        }
    }
}
