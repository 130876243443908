@font-face {
    font-family: 'Ultramagnetic';
    src: url('https://fonts.bridgestoneresources.com/ywft-ultramagnetic.woff2') format('woff2');
}

@font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: normal;
    src: url('https://fonts.bridgestoneresources.com/Avenir45Book_normal_normal.woff2') format('woff2');
}

@font-face {
    font-family: 'AvenirHeavy';
    font-style: normal;
    font-weight: normal;
    src: url('https://fonts.bridgestoneresources.com/Avenir85Heavy_normal_normal.woff') format('woff2');
}
