.appt-step-1 {
    .error {
        color: $brand-color-red;
    }

    .map {
        border: solid 1px $appt-step-border-color-dark;
    }

    input {
        padding: 8px;
        width: 42%;
    }

    .search-block-mobile {
        background: linear-gradient(to bottom, #eee, transparent 50px);
    }
}

.appt-step-1-results {
    border: solid 1px $appt-step-border-color-dark;

    @include media(bp-lt-sm) {
        border: none;
    }

    .result {
        @include media(bp-lt-sm) {
            background: linear-gradient(to bottom, #eee, transparent 50px) !important;
        }

        &:nth-child(odd) {
            background: #e5f1f9;

            @include media(bp-lt-sm) {
                background: none;
            }
        }

        .ctas-mobile {
            a {
                @include font-title;
                text-transform: uppercase;
                text-decoration: none;
            }

            .label {
                font-size: 12px;
            }
        }

        .open-nights {
            @include font-title;
            font-size: 16px;
        }

        .holiday {
            color: $brand-color-red;
        }
    }
}

.widemap {
    @include media(bp-lt-sm) {
        .appt-step-1-results {
            .result {
                background: none !important;
            }
        }
    }
}
