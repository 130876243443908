@import '~fcac/scss/shared/shared';

.maintenance-schedule {
    .content {
        span {
            @include font-body-bold;
        }
    }

    .miles-swiper {
        .slide {
            a {
                @include font-body-bold;
                font-weight: normal;
            }
            &.active {
                a {
                    background: $brand-color-red;
                }
            }
        }
    }

    .details-swiper {
        .slide {
            &.active {
                .content {
                    background: $brand-color-red;
                }
            }
        }
    }

    .periodic-swiper {
        .title {
            background: $brand-color-red;
        }
    }
}
