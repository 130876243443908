.rm-open-body .rm-modal,
.my-account,
.app-global {
    .footnote-serv-rec {
        margin: 5px 5px 20px;
        line-height: 1.3;
        font-size: 14px;

        p {
            margin-bottom: 8px;
            font-size: 14px;
        }

        ul {
            list-style: none;
            padding: 0;
            margin-top: 10px;

            li:before {
                content: '\2714';
                display: inline-block;
                width: 18px;
                height: 22px;
                color: #ccc;
            }
            li.invalid {
                &:before {
                    content: '\2716';
                    color: red;
                }
            }
            li.valid {
                &:before {
                    content: '\2714';
                    color: green;
                }
            }
        }
        .valid {
            color: green;
        }
        .invalid {
            color: red;
        }
        &.valid {
            ul {
                li.invalid {
                    color: #ccc;
                    &:before {
                        content: '\2014';
                        color: #ccc;
                    }
                }
            }
        }
    }
}
