@import '~fcac/scss/shared/shared';

.site-footer,
.site-footer a {
    @include font-body;
    text-decoration: none;
}

.site-footer {
    @include media(bp-lt-sm) {
        background: none;
        border: none;
    }

    .btn {
        border: 0;
        padding: 5px 9px;
        line-height: 1;
        vertical-align: middle;
        background-color: $dark-gray;
    }
}

.footer-heading {
    @include font-body-bold;
}

.footer-links {
    .col {
        width: 25%;
    }
}

.footer-tools {
    a {
        color: $brand-color-red;
        text-decoration: underline;
    }

    @include media(bp-lt-sm) {
        .offers-signup {
            display: none;
        }
    }
}

.footer-tools {
    .offers-signup {
        h3 {
            @include font-body-bold;
        }
    }
}

.footer-bottom {
    .footer-logo {
        min-width: 69px;

        img {
            width: 49px;
        }
    }
}
