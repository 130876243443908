@mixin cta-link($font-size: 18px, $mobile-font-size: 16px, $arrow-color: $brand-color-red, $text-color: $text-color) {
    .cta-link {
        @include font-title;

        font-size: $font-size;
        color: $text-color;
        text-decoration: none;

        &:after {
            content: '>';
            margin-left: 6px;
            color: $arrow-color;
        }

        &:hover {
            text-decoration: none;
            color: $arrow-color;
        }

        @include media(bp-lt-md) {
            font-size: $mobile-font-size;
        }
    }
}
