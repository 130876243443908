@import '~fcac/scss/shared/shared';

.email-signup {
    .heading {
        @include media(bp-lt-sm) {
            font-size: 30px;
        }
    }

    .inner-wrap {
        background: linear-gradient(to top, #eee, transparent 150px);

        @include media(bp-lt-sm) {
            background: none;
        }
    }
}

.email-signup-form {
    label {
        @include font-body-bold;
    }

    button {
        padding-left: 40px;
        padding-right: 40px;
    }

    .form-field {
        &.state {
            select.default-label {
                @include media(bp-lt-sm) {
                    @include font-body-bold;
                    color: #666;
                }
            }
        }
    }

    .signup-optin {
        label {
            font-family: inherit !important;
        }
    }
}
