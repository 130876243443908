@import '~fcac/scss/shared/shared';

header {
    & nav.top-nav {
        li .sub-nav {
            background: #ebebeb;
        }

        img {
            max-width: auto;
        }

        a.find-a-store {
            padding: 12px 15px;
            color: $white;
        }
    }
}

.primary-nav > a {
    @include font-body;
}

.primary-nav > ul > li > a {
    height: 90px !important;
    line-height: normal;
}

.primary-nav span.icon img,
.primary-nav > ul > li > a.logo span {
    padding: 0 !important;
    > img {
        max-width: none;
    }
}

.primary-nav .store-info {
    > a {
        text-align: left;
        font-size: 11px;
        text-transform: none;
        width: auto;
        display: table;
        height: 100%;
        cursor: default;
        span {
            display: table-cell;
            vertical-align: middle;
        }

        span:first-child {
            padding-right: 8px;
            strong {
                font-size: 14px;
                padding-right: 20px;
                background: url('/etc.clientlibs/bsro/fcac/clientlibs/clientlib-images/v1/resources/top-nav-down-arrow-solid.png')
                    transparent center right no-repeat;
            }
        }

        span.phone-number {
            @include font-body-bold;
            font-size: 24px;
            font-weight: bold;
            white-space: nowrap;
        }
    }

    .sub-nav {
        padding: 32px;
        //background-color: #d8d6d7;
    }

    .col.map {
        width: 45.147%;
    }

    .col.details {
        width: 54.8529%;
        padding-left: 32px;
        border-left: 1px solid #cccccc;
        font-size: 14px;
        color: $dark-gray;
        line-height: 1;

        a {
            color: $brand-color-red;
            font-size: 16px;
            font-weight: normal;
        }

        h3 {
            @include font-title;
            font-size: 40px;
            font-weight: normal;
            color: $brand-color-red;
            margin-bottom: 16px;
        }

        .directions {
            @include font-title;
            font-size: 14px;
            font-weight: normal;
        }

        table {
            font-size: 16px;

            tr.closed {
                color: $brand-color-red;
            }
        }

        .reviews .reviews-inline-rating {
            a,
            .btn-inline {
                color: #4a4a4a;
                font-size: 12px;
            }
        }
    }

    .holiday {
        color: $brand-color-red;
    }

    .directions {
        text-align: center;
        text-transform: uppercase;

        &:before {
            @include icon('map-signs', $brand-color-red);
            display: block;
            margin: 0 auto;
        }
    }
}

.sub-nav-content {
    .service-items {
        width: 63.6036%;
        border-right: 1px solid #ccc;

        > a {
            line-height: 1;
            span {
                display: inline-block;
                vertical-align: middle;

                &:first-child {
                    width: 35%;
                }

                &:last-child {
                    width: 65%;
                }
            }
        }
    }

    .service-items h3 {
        @include font-title;
        font-size: 20px;
        font-weight: normal;
        margin-bottom: 20px;
    }

    .schedule-cta {
        width: 36.3963%;
        padding-left: 40px;
        font-size: 16px;
    }

    .schedule-cta h5 {
        margin-bottom: 12px;
        font-size: 16px;
    }

    .schedule-cta p {
        padding-right: 30%;
        margin-bottom: 27px;
    }

    .schedule-cta .btn {
        color: $white !important;
        padding: 0 20px;
        height: 50px;
        line-height: 50px;
    }

    .services .col {
        height: auto;
    }

    .services .col > a span.icon {
        width: 47px;
        height: 30px;
    }
}

.sub-nav {
    padding-left: 22.9166%;

    @include media(bp-lt-lg) {
        padding-left: 15%;
    }
}

.sub-nav.sub-nav-content .tabs.shop-for-tires,
.sub-nav.sub-nav-content .services {
    width: 100%;
    padding-left: 20px;

    span.icon img {
        max-width: 48px;
        padding: 8px 0 !important;
    }
}

.sub-nav.sub-nav-content .tabs.shop-for-tires .col {
    margin-right: 0;
    padding-right: 30px;
    width: 20%;
    height: auto;

    &:last-child,
    &.last-child {
        margin-right: 0;
        padding-right: 0;
    }

    > a {
        padding-right: 10px;
    }
}

.sub-nav.sub-nav-content .services {
    .service-items {
        padding-right: 40px;
    }

    .col {
        margin-right: 0;
        padding-right: 30px;
        width: 33%;

        &:last-child,
        &.last-child {
            margin-right: 0;
            padding-right: 0;
        }

        > a {
            padding-right: 10px;
        }
    }
}

.sub-nav-content .tabs.shop-for-tires {
    padding: 20px 0 40px 20px;
    left: auto;

    h3 {
        @include font-title;
        font-weight: normal;
    }

    .tab-content .shop-by-vehicle {
        width: 75%;
        a {
            width: 32%;
            display: inline-block;
            white-space: nowrap;

            span {
                margin-right: 15px;

                @include media(bp-md) {
                    margin-right: 0;
                }
                &:first-child {
                    margin-bottom: 15px;
                }
                &:last-child {
                    margin-right: 30px;
                }

                &.icon img {
                    height: 60px;
                    max-width: 42px;
                }
            }
        }
    }
}

.sub-nav.sub-nav-content .mobile.back {
    display: none;
}

.sub-nav.sub-nav-content.tires {
    background: url('https://s7d1.scene7.com/is/image/bridgestone/tires-bg-nav-global-web-bsro?scl=1') 22.9166% 100%
        no-repeat;

    @include media(bp-md) {
        background-position: 37% 100%;
    }
}

.sub-nav.sub-nav-content.repair {
    background: url('https://s7d1.scene7.com/is/image/bridgestone/repair-bg-nav-global-web-bsro?scl=1') 22.9166% 100%
        no-repeat;

    @include media(bp-md) {
        background-position: 35% 100%;
    }
}

.sub-nav.sub-nav-content.maintenance {
    background: url('https://s7d1.scene7.com/is/image/bridgestone/maintenance-bg-nav-global-web-bsro?scl=1') 22.9166%
        100% no-repeat;

    @include media(bp-md) {
        background-position: 35% 100%;
    }
}

/*
.primary-nav > ul > li {
    width: 10%;

    &:first-child {
        width: 25%;
    }

    &:last-child,
    &.last-child {
        width: 25%;
    }
}*/

.primary-nav > ul > li > a span {
    padding-right: 18px;
    padding-left: 18px;
}

.primary-nav .logo-wrapper,
.primary-nav .main-nav-wrapper,
.primary-nav .store-wrapper {
    float: left;
}

.primary-nav .logo-wrapper,
.primary-nav .store-wrapper {
    width: 20%;
}

.primary-nav .logo-wrapper {
    //min-width: 227px;
    text-align: right;
    padding-left: 5px; // 4.8611% - actual
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
    a {
        display: block;
    }
}

#SetZipForm {
    display: none;
    padding-top: 15px;

    input {
        width: 12%;
    }

    .btn {
        padding: 7px 15px;
        vertical-align: bottom;
        border: unset;
    }
}

.primary-nav .store-wrapper-mobile {
    display: none;
    width: 100%;
    float: left;

    .label {
        padding: 5px 0 5px 10px;
    }

    .bar {
        overflow: hidden;
        height: 50px;
        padding: 0 0 0 5px;
        background: #e3e3e3;
    }

    p {
        float: left;
        color: $dark-gray;
        margin: 5px 0 0 5px;
        font-size: 13px;
        padding-top: 2px;

        strong {
            display: table;

            span {
                display: table-cell;
                vertical-align: middle;
                height: 40px;
            }
        }
    }

    a {
        position: relative;
        width: 105px;
        height: 50px;
        margin-left: -150px;
        padding: 18px 0 0 33px; //10px 0;
        float: right;
        color: $white;
        text-align: left; //center;
        font-size: 13px;
    }

    .ficon-phone:before {
        display: block;
        position: absolute;
        top: 11px;
        left: 5px;
        font-size: 26px;
    }
}

.primary-nav .store-wrapper {
    padding-right: 5px; // 4.8611%;
}

.primary-nav .main-nav-wrapper {
    width: 58%;

    > ul {
        position: static;
        width: auto;
        margin: 0 auto;

        > li {
            width: auto;
        }
    }
}

.store-info {
    height: 90px;
    float: left;

    &:hover .sub-nav {
        top: auto;
    }

    .sub-nav {
        -webkit-box-shadow: 0px 5px 8px -4px rgba(0, 0, 0, 0.6);
        -moz-box-shadow: 0px 5px 8px -4px rgba(0, 0, 0, 0.6);
        box-shadow: 0px 5px 8px -4px rgba(0, 0, 0, 0.6);

        position: absolute;
        top: -999em;
        right: 0;
        background: url('/etc.clientlibs/bsro/fcac/clientlibs/clientlib-images/v1/resources/top-nav-sub-nav-your-store-bg.png')
            0 0 repeat-x;
    }

    span.phone-number {
        padding-left: 10px;
    }
}

.primary-nav .main-nav-wrapper li.mobile {
    display: none;
}

.sub-nav.sub-nav-content.tires,
.sub-nav.sub-nav-content.repair,
.sub-nav.sub-nav-content.maintenance {
    h3.back {
        display: none;
    }
}

.hamburger {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
}

.hamburgler {
    display: block;
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 55px;
    height: 60px;
    font-size: 0;
    text-indent: -9999px;
    appearance: none;
    box-shadow: none;
    border-radius: none;
    border: none;
    cursor: pointer;
    transition: background 0.3s;
}

.hamburgler:focus {
    outline: none;
}

.hamburgler span {
    display: block;
    position: absolute;
    top: 45%;
    left: 15px;
    right: 15px;
    height: 4px;
    background: #666666;
}

.hamburgler span::before,
.hamburgler span::after {
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #666666;
    content: '';
}

.hamburgler span::before {
    top: -12px;
}

.hamburgler span::after {
    bottom: -12px;
}

.hamburgler--btn {
    background-color: transparent;
}

.hamburgler--btn span {
    transition: background 0s 0.3s;
}

.hamburgler--btn span::before,
.hamburgler--btn span::after {
    transition-duration: 0.3s, 0.3s;
    transition-delay: 0.3s, 0s;
}

.hamburgler--btn span::before {
    transition-property: top, transform;
}

.hamburgler--btn span::after {
    transition-property: bottom, transform;
}

/* active state, i.e. menu open */
.hamburgler--btn.is-active {
    background-color: transparent;
}

.hamburgler--btn.is-active span {
    background: none;
}

.hamburgler--btn.is-active span::before {
    top: 0;
    transform: rotate(45deg);
}

.hamburgler--btn.is-active span::after {
    bottom: 0;
    transform: rotate(-45deg);
}

.hamburgler--btn.is-active span::before,
.hamburgler--btn.is-active span::after {
    transition-delay: 0s, 0.3s;
}

.no-local-store {
    #SetZipForm {
        display: block;
    }

    .primary-nav .store-wrapper-mobile {
        display: none;
    }

    .store-wrapper {
        display: none;
    }
}

.has-local-store {
    #SetZipForm {
        display: none;
    }

    .store-wrapper {
        display: block;
    }
}

@media only screen and (max-width: 1201px) {
    .primary-nav .main-nav-wrapper > ul > li {
        //width: 20%;
    }

    .primary-nav .logo-wrapper {
        //padding-left: 3%;
    }
    .primary-nav .store-wrapper {
        //padding-right: 3%;
    }

    .sub-nav.sub-nav-content .tabs.shop-for-tires,
    .sub-nav.sub-nav-content .services {
        padding-left: 10px;
    }

    .sub-nav.sub-nav-content .services .col {
        padding-right: 15px;
    }

    .sub-nav.sub-nav-content .services .service-items {
        padding-right: 20px;
    }

    .sub-nav-content .service-items,
    .sub-nav-content .schedule-cta {
        padding-left: 20px;
    }

    .primary-nav .store-info > a span {
        display: table-row;
    }

    .primary-nav .main-nav-wrapper > ul {
        width: 100%;

        > li {
            width: 20%;
        }
    }

    .store-info {
        float: right;
        padding-top: 10px;
    }
}

.no-scroll {
    overflow: hidden;
}

@media only screen and (max-width: 1100px) {
    .primary-nav .logo-wrapper {
        //padding-left: 3%;
    }
    .primary-nav .store-wrapper {
        //padding-right: 3%;
    }

    .sub-nav-content .schedule-cta .btn {
        font-size: 17px;
    }

    .tabs.shop-for-tires .tab-content {
        padding-left: 27px;
    }

    .sub-nav-content {
        .service-items {
            > a {
                span {
                    &:first-child {
                        width: 40%;
                    }

                    &:last-child {
                        width: 60%;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .primary-nav > ul > li > a i.arrow-down {
        bottom: 2px;
    }

    .primary-nav > ul > li > a span {
        padding: 0 5px !important;
    }

    .sub-nav.sub-nav-content .tabs.shop-for-tires,
    .sub-nav.sub-nav-content .services {
        padding-left: 0px;
    }

    .secondary-nav .links {
        padding-right: 10px;
    }
}

@media only screen and (max-width: 960px) {
    .sub-nav-content .col > a {
        font-size: 16px;
    }

    .sub-nav-content .schedule-cta h5 {
        font-size: 15px;
    }

    .sub-nav.sub-nav-content .tabs.shop-for-tires,
    .sub-nav.sub-nav-content .services {
        padding-left: 0px;
    }

    .sub-nav.sub-nav-content .services .service-items {
        padding-left: 0px;
    }

    .sub-nav-content .schedule-cta .btn {
        font-size: 16px;
        padding: 0 5px;
    }

    .primary-nav > ul > li > a.logo span {
        > img {
            max-width: 100%;
        }
    }
}

@media only screen and (max-width: 900px) {
    // .primary-nav .logo-wrapper {
    //     position: absolute;
    //     top: -5px;
    //     text-align: left;
    //     padding-top: 0;

    //     img {
    //         height: 50px;
    //     }

    //     img.ninety-years {
    //         position: relative;
    //         top: 5px;
    //         height: 40px;
    //     }
    // }

    // .primary-nav .main-nav-wrapper {
    //     width: 76%;
    // }

    .primary-nav .logo-wrapper {
        width: 22%;
    }

    .primary-nav .main-nav-wrapper {
        width: 78%;
    }

    .primary-nav .store-wrapper-mobile {
        .ficon-phone {
            display: none;
        }
    }

    #SetZipForm {
        padding-top: 0;
        text-align: left;
        margin-bottom: auto !important;
        float: left;
        clear: both;
        width: 100%;

        .label {
            padding: 5px 0 5px 10px;
        }

        .bar {
            height: 50px;
            padding: 0 0 0 5px;
        }

        input {
            width: 20%;
            margin: 8px 8px 0 5px;
            float: left;
        }

        .btn {
            display: inline-block;
            position: relative;
            width: 105px;
            height: 35px;
            padding: 11px;
            margin-top: 8px;
            float: left;
            color: $white;
            text-align: center;
            font-size: 13px;
        }
    }

    .has-local-store {
        .store-wrapper-mobile {
            display: block;
        }

        .store-wrapper {
            display: none;
        }
    }
}

@media only screen and (max-width: 768px) {
    .sub-nav {
        padding-left: 20px;
    }

    .sub-nav.sub-nav-content.tires,
    .sub-nav.sub-nav-content.repair,
    .sub-nav.sub-nav-content.maintenance {
        background-position: -330px 0;
    }

    .sub-nav.sub-nav-content .tabs.shop-for-tires .col {
        width: 29%;
    }

    .sub-nav.sub-nav-content .tabs.shop-for-tires .tab-nav > ul > li {
        padding-right: 5px;
    }

    // .sub-nav.sub-nav-content .tabs.shop-for-tires span.icon {
    //display: none;
    // }

    // .sub-nav.sub-nav-content .tabs.shop-for-tires .tab-nav a {
    //     line-height: normal;
    //     font-size: 16px;
    // }

    .primary-nav > ul > li:hover > a,
    .primary-nav > ul > li:hover {
        background-color: transparent;
    }

    .primary-nav .store-info > a span.phone-number {
        font-size: 21px;
    }

    .sub-nav-content .schedule-cta {
        padding-right: 10px;
    }

    .primary-nav .logo-wrapper {
        width: 40%;
    }

    .primary-nav .main-nav-wrapper {
        width: 73%;
    }

    .primary-nav .store-wrapper-mobile {
        .ficon-phone {
            display: block;
        }
    }
}

@media only screen and (max-width: 680px) {
    // Sean: commented this out because it was preventing all pages from scrolling at this breakpoint and below
    // body {
    //     overflow: hidden;
    // }

    header > nav.top-nav {
        height: 60px;
    }

    .secondary-nav {
        display: none;
    }

    .primary-nav {
        height: 60px !important;
    }

    .primary-nav > ul > li,
    .primary-nav > ul > li > a {
        height: 48px !important;
    }

    .primary-nav .store-wrapper-mobile {
        //display: inline-block;
    }

    #SetZipForm {
        .bar {
            background: #e3e3e3;
        }

        input {
            float: none;
            width: 62%;
        }

        .btn {
            height: 50px;
            padding: 19px;
            margin-top: 0;
            float: right;
        }
    }

    .store-wrapper {
        display: none;
    }

    .sub-nav {
        top: 60px;
        right: 0;
    }

    .primary-nav .logo-wrapper {
        position: relative;
        height: 60px;
        top: 0;

        padding: 0 15px;
    }

    .primary-nav .main-nav-wrapper {
        width: 100vw;
        height: 100vh !important;
        background-color: $dark-gray;
        padding: 15px 15px 130px; // bottom padding offsets the nav top position to allow for scrolling
        overflow-y: scroll;
        overflow-x: hidden;
        position: absolute;
        z-index: 100;

        &.slide-left {
            transition: 0.5s;
            left: -100vw;
            overflow: visible;
        }
    }

    header > nav.top-nav li:hover .sub-nav {
        top: 0;
    }

    .hamburger {
        display: block;
    }

    .primary-nav .main-nav-wrapper ul {
        height: auto !important;
    }

    .primary-nav .main-nav-wrapper .sub-nav {
        /*height: 800px !important;
        background-image: none !important;
        top: 60px !important;
        background-color: $dark-gray;*/

        &.is-active {
            height: 100vh;
            overflow-y: scroll;

            .sub-nav {
                height: 100vh;
                padding-bottom: 0;
            }

            &.auto {
                padding-bottom: 130px;

                .services {
                    height: auto;
                    float: none;
                }
            }
        }
    }

    .primary-nav .main-nav-wrapper ul li.mobile {
        display: table-row;

        &:first-child a {
            border-top: 0;
            margin-bottom: 20px;
            text-align: center;
        }
    }

    .sub-nav.sub-nav-content .mobile.back {
        display: inline-block;
        margin-right: 32px;
    }

    .primary-nav .main-nav-wrapper ul li.desktop {
        display: none;
    }

    .primary-nav .main-nav-wrapper ul li {
        width: 100%;
        display: table-row;
        border: 0;

        a {
            text-align: left;
            color: $white;
            border-top: 1px solid $white;
            font-size: 17px;
        }
    }

    .sub-nav-content .schedule-cta {
        display: none;
    }

    .primary-nav > ul > li:hover > a i.arrow-down {
        display: none !important;
    }

    .sub-nav-content {
        overflow-y: scroll;

        .service-items {
            > a span:last-child {
                width: 85%;
                line-height: 1.2em;
            }
        }
    }

    .sub-nav.sub-nav-content.tires,
    .sub-nav.sub-nav-content.repair,
    .sub-nav.sub-nav-content.maintenance {
        top: 0px !important;
        right: auto;
        height: 800px;
        background-image: none;
        background-color: $dark-gray;
        display: none;
        padding-top: 15px;
        transition: 0.5s;
        left: 100vw;

        &.is-active {
            display: block;
        }

        h3 {
            color: $white;
            font-size: 17px;
        }

        a {
            background-image: none;
            color: $white !important;
            width: 100%;
            height: auto;
            margin-bottom: 1em;
            border: 0;
        }

        h3.back {
            display: block;
            border-bottom: 1px solid $white;
            padding-bottom: 15px;
            margin-bottom: 0;
        }

        .col {
            width: 100% !important;
            padding-left: 15px;
            margin-bottom: 0;
        }

        span.icon {
            display: none !important;
        }
    }
    .show:not(.interrupt) {
        display: block !important;
    }

    .sub-nav.sub-nav-content.tires .tabs.shop-for-tires {
        position: relative;
        padding-top: 15px;

        .tab-nav,
        .tab-content {
            display: inline-block;
            width: 100%;
            height: 113%;
            padding-left: 40px;

            h3 {
                margin-bottom: 10px;
            }

            .shop-by-vehicle {
                width: 100%;
            }
        }

        .tab-nav {
            height: auto;
            border-right: 0;
            border-bottom: 1px solid $white;
            padding-bottom: 10px;
            margin-bottom: 20px;

            > ul {
                padding-left: 15px;
            }

            .indicator {
                display: none;
            }
        }
    }

    .sub-nav.sub-nav-content.repair,
    .sub-nav.sub-nav-content.maintenance {
        .services {
            padding-top: 0;
        }

        .service-items {
            width: 100%;
            padding-left: 40px;
            border-right: 0;
            padding-top: 15px;
        }
    }
}

@media only screen and (max-width: 425px) {
    .sub-nav.sub-nav-content.tires .tabs.shop-for-tires {
        .tab-content {
            .shop-by-vehicle {
                a {
                    width: 45%;
                }
            }
        }
    }
}

// A/B Testing styles for sub-nav shop-by-vehicles
@media only screen and (max-width: 680px) {
    .shop-for-tires .tab-nav {
        li:last-child {
            height: 0 !important;
        }
    }
}

.shop-by-vehicle {
    @media only screen and (max-width: 768px) {
        width: 90% !important;
    }

    @media only screen and (max-width: 680px) {
        width: 95% !important;
    }

    &.tire-brands--v2 {
        @include media(bp-xs) {
            h3 {
                margin-bottom: 20px !important;
            }
        }
    }

    .tire-brands {
        &__wrapper {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            @include media(bp-gt-md) {
                width: 87%;
            }

            @media only screen and (max-width: 680px) {
                flex-direction: column;
                width: 93%;
            }
        }

        &__section {
            flex: 0 50%;
            margin-bottom: 15px;
            position: relative;

            &--firestone {
                border-bottom: 1px solid #7f7f7f;

                @media only screen and (min-width: 681px) {
                    padding-left: 20px;
                    border-bottom: 0;
                }
            }

            &--bridgestone {
                border-bottom: 1px solid #7f7f7f;

                @media only screen and (min-width: 681px) {
                    border-right: 1px solid #c7c7c7;
                    padding-right: 20px;
                    border-bottom: 0;
                }
            }

            &--suredrive {
                .tire-brands__heading {
                    margin-top: 10px;
                }
            }
        }

        &__heading-image {
            height: 18px;

            @include media(bp-lt-lg) {
                height: 15px;
            }

            @media only screen and (max-width: 680px) {
                height: 18px;
            }
        }

        &__heading-cta {
            font-size: 14px !important;
            font-weight: 600;
            width: 100% !important;
            display: flex !important;
            justify-content: space-between;
            align-items: center;

            &:hover {
                color: $brand-color-red;
            }

            span {
                margin-right: 0 !important;
            }

            @media only screen and (max-width: 680px) {
                font-weight: normal;
            }
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
        }

        &__list-item {
            flex: 0 43%;
            padding: 15px 0;
            border-top: 1px solid #c7c7c7;
            position: relative;

            @media only screen and (max-width: 680px) {
                border-top: 1px solid #7f7f7f !important;
                margin-bottom: 0 !important;
            }

            &:hover {
                span:first-child {
                    color: $brand-color-red;
                }
            }

            &__title {
                margin-bottom: 0;
                font-weight: 800;
                @include font-body-bold;
                font-size: 16px;
                color: $black;
                display: block;
                margin-bottom: 6px !important;
                line-height: 15px;

                @media only screen and (max-width: 680px) {
                    color: $white;
                }
            }

            &__subtitle {
                display: block;
                color: #4a4a4a;
                margin-top: -3px;
                font-style: italic;
                font-size: 12px;
                white-space: normal;
                width: 100%;
                line-height: 12px;

                @media only screen and (max-width: 680px) {
                    color: #c1c1c1;
                }
            }
        }

        &__section {
            &--firestone,
            &--bridgestone {
                .tire-brands__list-item:nth-child(1),
                .tire-brands__list-item:nth-child(2) {
                    border-top: 0px;
                }

                @media only screen and (max-width: 680px) {
                    .tire-brands__list-item:nth-child(1),
                    .tire-brands__list-item:nth-child(2) {
                        border-top: 0px !important;
                    }
                }
            }
        }

        &--v2 {
            .tire-brands {
                &__section {
                    &--show-desktop {
                        display: block;

                        @media only screen and (max-width: 680px) {
                            display: none;
                        }
                    }
                    &--show-mobile {
                        display: none;

                        @media only screen and (max-width: 680px) {
                            display: block;
                        }
                    }
                }
                &__section--bridgestone .tire-brands__list-item__icon path:first-child {
                    fill: #fe0000;

                    @media only screen and (max-width: 680px) {
                        fill: $white;
                    }
                }

                &__section--firestone .tire-brands__list-item__icon path {
                    fill: #fe0000;

                    @media only screen and (max-width: 680px) {
                        fill: $white;
                    }
                }

                &__heading-cta {
                    font-size: 16px !important;
                    float: left;
                    @include font-body-bold;

                    @include media(bp-lt-lg) {
                        font-size: 13px !important;
                    }
                }

                &__list-item {
                    @include media(bp-lt-xl) {
                        flex: 0 45%;
                    }

                    &:hover {
                        span:nth-child(2) {
                            color: $brand-color-red;
                        }
                    }

                    &__title {
                        margin-left: 35px;

                        @media only screen and (max-width: 680px) {
                            margin-left: 30px;
                        }

                        @include media(bp-lt-xl) {
                            margin-left: 25px;
                            font-size: 14px;
                        }

                        @include media(bp-lt-lg) {
                            margin-left: 25px;
                            margin-bottom: 4px !important;
                            font-size: 13px;
                        }

                        @media only screen and (min-width: 769px) and (max-width: 880px) {
                            margin-left: 20px;
                            font-size: 12px;
                        }
                    }

                    &__subtitle {
                        margin-left: 35px;

                        @media only screen and (max-width: 680px) {
                            margin-left: 30px;
                        }

                        @include media(bp-lt-xl) {
                            margin-left: 25px;
                            font-size: 11px;
                        }

                        @include media(bp-lt-lg) {
                            margin-left: 25px;
                            font-size: 10px;
                            width: 83%;
                        }

                        @media only screen and (min-width: 769px) and (max-width: 880px) {
                            margin-left: 20px;
                            font-size: 10px;
                        }
                    }

                    &__icon {
                        position: absolute;
                        top: 13px;
                        left: 0;
                        height: 35px;
                        width: 25px;

                        @include media(bp-lt-xl) {
                            top: 18px;
                            height: 20px;
                            width: 17px;
                        }

                        @media only screen and (max-width: 680px) {
                            fill: $white;
                        }

                        @media only screen and (min-width: 769px) and (max-width: 880px) {
                            top: 18px;
                            height: 20px;
                            width: 15px;
                        }
                    }
                }
            }
        }
    }
}
