@import '~fcac/scss/shared/shared';

.fifty-fifty-basic {
    &.bg-gray-gradient {
        @include gradient-bottom();
    }
    &.bg-none {
        background: none;
        background-color: transparent;
    }
    &.bg-color {
        background: none;
        background-color: $bg-color;
    }
    .title {
        @include font-title;
        font-size: 24px;
        text-transform: uppercase;
    }

    @include media(bp-lt-md) {
        .title {
            font-size: 18px;
        }
    }
}
