@import '~fcac/scss/shared/shared';
.fifty-fifty {
    .title {
        @include font-title;

        margin-top: 0;
        text-transform: uppercase;
        color: $text-color;
        font-weight: normal;

        @include media(bp-lt-md) {
            font-size: 30px;
        }
    }

    .subtitle {
        @include font-title;

        color: $text-color;
        font-weight: normal;
    }

    ol {
        li {
            @include font-title;

            text-transform: uppercase;
        }
    }
}
