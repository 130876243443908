@import '~fcac/scss/shared/shared';

// add fcac specific styles and overrides here
.product-detail-v2 {
    @include media(bp-lt-md) {
        .container {
            margin: 0;
        }
        .ecomm-back-to-results {
            margin: 0 20px;
        }
        .carousel-heading {
            margin: 0 20px;
        }
    }

    &.reskin {
        // .performance {
        //     display: none !important; // remove performance
        // }
        .product-detail-photos-v2 {
            > .inner {
                > .brand-logo-container {
                    &.mobile {
                        .product-detail-tire-info-v2 {
                            &.mobile {
                                margin-top: 20px;
                            }
                        }
                    }
                }
            }

            @media print {
                width: 100%;
            }
        }

        .product-detail-pricing-branding-v2 {
            .product-detail-price-block-v2.ecom {
                .total {
                    .dollar-sign {
                        @include font-title;
                    }
                }
            }
        }

        // .product-detail-features-benefits {
        // }
    }
    .content-carousel.reskin {
        background: linear-gradient(#ebebeb, $white, #ebebeb);
        padding-top: 50px;

        .container {
            background: none;
            margin: 0 auto;
            padding: 0;
            text-align: center;
            max-width: 768px; // carousel[data-slides-desktop="2"]

            @include media(bp-lt-md) {
                background: #ebebeb;
            }

            .content-log {
                width: 74px;
                display: block;
                margin: -74px auto 0;
            }
        }

        .desc {
            margin-left: auto;
            margin-right: auto;
            padding: 0 20%;
        }

        .title-text-cta {
            background: none;
            padding: 0;
        }

        .carousel {
            margin-top: 0;

            img {
                width: 100%;
            }

            p {
                width: 82%;
                margin: 20px auto;
                line-height: 1;
            }

            .container {
                display: inline-block;
                max-width: 344px;
                border: 1px solid #ebebeb;
                padding: 12px 12px 20px 12px;
                background: $white;
            }

            .title {
                font-size: 24px;
            }
        }

        a {
            @include font-title;
            text-transform: uppercase;
            text-decoration: none;
            font-size: 16px;
            text-align: center;
            margin: 0 auto;
            display: block;

            span {
                color: $dark-gray;
            }
        }

        .cta {
            a.btn {
                background-color: transparent;
                color: $dark-gray;
                padding: 0;
                border: 0;

                &:after {
                    content: '\003e';
                    color: $brand-color-red;
                    margin-left: 5px;
                }
            }
        }

        @include media(bp-lt-md) {
            margin-top: 70px;
            padding-top: 40px;

            .container {
                position: relative;

                .content-log {
                    position: absolute;
                    margin-left: -37px;
                    left: 50%;
                    top: -14px;
                }
            }

            .slick-slide {
                float: none;
                padding: 15px;
            }

            .slick-track {
                width: 100% !important;
            }

            .slick-dots {
                display: none !important;
            }
        }
    }

    .write-a-review-button {
        p {
            margin: 5px 0px 0px 0px;
        }
    }
}

.product-detail-info-v2 {
    .tire-name {
        @include font-title;
        font-size: 40px;

        @include media(bp-lt-lg) {
            font-size: 30px;
        }

        @include media(bp-lt-sm) {
            font-size: 24px;
        }
    }

    .tire-size {
        @include font-body-bold;
        font-size: 18px;

        @include media(bp-lt-sm) {
            font-size: 14px;
        }
    }

    .desc {
        margin: 5px 0 15px;
    }
}

.product-detail-price-block-v2 {
    .price {
        @include font-title;
        color: $brand-color-red;
    }

    .unit,
    .unitPrefix {
        @include font-body;
        color: $text-color;
    }

    .total {
        @include font-body-bold;
    }
}

.product-detail-tire-info-v2 {
    dt {
        @include font-body-bold;
    }
}

.product-detail-tread-info-v2 {
    .wrap {
        > div {
            @include font-title;
            font-size: 17px;

            @include media(bp-lt-sm) {
                font-size: 14px;
            }

            span {
                color: #808080;
            }
        }

        .cta {
            @include font-body;
            font-size: 14px;
        }
    }
}

.product-detail-tabs-v2 {
    .active {
        h3 {
            margin: 20px 0 0;
        }

        p {
            margin: 8px 0 0;
        }
    }
}
