@import '~fcac/scss/shared/shared';

$tires-service-default-color: white;
$tires-service-tabs-bg: #951200;
$tires-service-tabs-bg-active: $brand-color-red;
$tires-service-tabs-border-top-color: #d62118;
$tires-service-tabs-size: 14px;

$tires-service-tab-content-bg-active: $brand-color-red;
$tires-service-tab-content-tabs-bg: #7c1709;
$tires-service-tab-content-tabs-height: 30px;
$tires-service-tab-content-heading-size: 26px;

$tires-service-tab-content-btn-height: 49px;
$tires-service-tab-content-btn-border: 3px;
$tires-service-tab-content-btn-border-color: #951200;
$tires-service-tab-content-btn-color: #961200;
$tires-service-tab-content-btn-size: 16px;

$tires-service-tab-content-btn-blue-color: $brand-color-blue;
$tires-service-tab-disable-color: #808080;
$tires-service-tab-content-font-color: $black;

.tire-service-widget-v3 {
    color: $tires-service-default-color;

    .tab-nav > ul > li {
        margin-bottom: 0;
        border: 1px solid #4a4a4a;
        background: $tires-service-default-color;

        &.active {
            border: 1px solid $tires-service-tabs-border-top-color;
            background: $tires-service-default-color !important;
            > a {
                color: $tires-service-default-color !important;
            }
        }

        > a {
            @include font-title;
            color: #4a4a4a;
            font-size: $tires-service-tabs-size;

            @media only screen and (max-width: 900px) {
                font-size: 30px;
            }

            span {
                padding-left: 0;
            }

            .icon {
                display: block;
            }
        }
    }

    .tab-content {
        .tooltip {
            line-height: 2.6;
            text-decoration: underline;
            cursor: pointer;
            color: $black;
        }

        section {
            color: $tires-service-tab-content-font-color;

            .heading {
                color: $tires-service-default-color;
                @include font-title;
                font-size: $tires-service-tab-content-heading-size;
                margin: 0 0 15px 0;
                text-transform: uppercase;
            }

            &.active {
                background: $tires-service-default-color;
            }

            a {
                color: $tires-service-default-color;
            }

            .store-map,
            .store {
                display: inline-block;
                margin-bottom: 10px;
                .holiday {
                    color: $tires-service-default-color !important;
                }
            }

            .store .location {
                width: 70%;
                float: left;
                margin-top: 0;
            }

            .store p.directions {
                width: 30%;
                float: left;
                margin-top: 40px;

                a {
                    display: block;
                    text-align: center;
                    text-transform: uppercase;
                    text-decoration: none;
                }
            }

            .store.hours {
                td {
                    &:first-child {
                        padding-right: 5px !important;
                    }
                }
            }

            .btn {
                width: 100%;
                padding: 6px 11px;
            }

            .btn.blue {
                background: $tires-service-tab-content-btn-blue-color;
                border: solid 2px #034e85;
            }

            .search-battery .zipcodeError {
                color: $brand-color-red;
            }
        }
    }

    form {
        .col {
            width: 100%;
            margin-bottom: 13px;
        }

        .col a {
            color: white;
        }

        .col.last {
            margin: 0;
        }

        .custom-select select,
        input {
            height: 32px;
        }

        fieldset .custom-select,
        fieldset input {
            float: left;
            margin-right: 10px;
            width: 60%;
        }
    }

    .top-nav {
        display: none;
    }

    .close-tab-content {
        display: none;

        .desktop {
            display: block;
        }

        .mobile {
            display: none;
        }
    }

    /* FCAC RESKIN */
    .reskin & {
        position: relative;
        width: 100%;

        .mobile-tab-nav {
            display: none !important;
        }

        > .tab-nav {
            position: absolute;
            width: 100%;
            float: none;
            height: 100%;
            top: 0;

            > ul {
                > li {
                    background: $tires-service-default-color !important;
                    border-top: none;
                    height: 25%;
                    width: 100%;

                    &:first-child {
                        display: none;
                    }

                    > a {
                        text-align: left;
                        font-size: 20px;
                        color: $tires-service-tab-content-font-color !important;
                        padding-left: 0;

                        span {
                            padding-left: 104px;
                            user-select: none;

                            &:before {
                                display: none !important;
                            }

                            .icon {
                                display: block !important;
                            }
                        }

                        .icon {
                            position: absolute;
                            top: 50%;
                            transform: translate(0, -50%);
                            left: 20px;
                        }
                    }
                }
            }

            &.reskin {
                padding: 55px 15px 0 15px;
                background: $tires-service-default-color;
                position: absolute !important;

                li {
                    border-bottom: 1px solid $black;

                    &:last-child {
                        border-bottom: 0;
                    }

                    a {
                        &:after {
                            position: relative;
                            display: table-cell;
                            color: $white;
                            content: '\203A';
                            top: -2px;
                            font-size: 40px;
                            vertical-align: middle;
                        }
                    }
                }

                @include media(bp-lt-md) {
                    li {
                        height: 24.9% !important;
                    }
                }

                @media only screen and (max-width: 700px) {
                    display: none;
                    height: 522px;
                    padding-top: 55px;
                }
            }
        }

        .top-nav {
            &.tab-top {
                ul {
                    li {
                        background: $tires-service-tab-disable-color !important;
                        &.active {
                            background: $tires-service-default-color !important;
                            border-top-color: $brand-color-red;
                            @media only screen and (max-width: 700px) {
                                background: $tires-service-default-color !important;
                            }
                            a {
                                color: $tires-service-tab-content-font-color !important;
                            }
                        }

                        &:first-child {
                            border-right-color: #781609 !important;
                        }
                    }
                }
            }
        }

        > .tab-content {
            background: $tires-service-default-color;
            width: 100%;
            float: none;
            padding: 15px;

            &.vehicle-lookup {
                height: 100%;
            }

            section {
                padding: 0;
                z-index: 3;

                .heading {
                    color: $brand-color-red;
                    border-bottom: 1px solid $dark-gray;
                    font-size: 24px;
                    margin-bottom: 15px !important;
                    padding-bottom: 15px;
                }
            }

            @include media(bp-lt-md) {
                height: 522px !important;
            }

            @media only screen and (max-width: 700px) {
                display: none;
            }
        }

        .tire-search-widget {
            padding-top: 40px !important;

            .heading {
                display: none;
            }

            .tab-nav {
                li.active {
                    display: table-cell !important;
                }
            }
            .tab-nav {
                width: 100%;
                margin: 0 auto;

                > ul {
                    > li {
                        width: 50%;

                        a {
                            color: #4a4a4a !important;
                            font-size: 16px;
                        }

                        &.active {
                            background: $white !important;

                            a {
                                color: $brand-color-red !important;
                            }
                        }
                    }
                }
            }
        }

        &.shop-tires {
            .top-nav {
                ul {
                    li.shop-tires {
                        &.active {
                            background: $tires-service-default-color !important;
                        }
                    }
                }
            }

            > .tab-nav.reskin {
                z-index: 0;
                display: none;
            }

            > .tab-content {
                display: block;

                .tire-search-widget {
                    display: block;
                    background: $tires-service-default-color;
                }
            }
        }

        &.get-services {
            .top-nav {
                ul {
                    li.get-services {
                        &.active {
                            background: $tires-service-default-color !important;
                        }
                    }
                }
            }

            > .tab-nav.reskin {
                z-index: 1;
                display: block;
            }

            > .tab-content {
                display: block;
            }
        }

        &.show-tab-content {
            > .tab-content {
                background: #f6f4f4;
                // background: -moz-linear-gradient(top, #f6f4f4 50%, #e0dfdf 100%);
                // background: -webkit-linear-gradient(top, #f6f4f4 50%, #e0dfdf 100%);
                background: linear-gradient(to bottom, #f6f4f4 50%, #e0dfdf 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6f4f4', endColorstr='#e0dfdf',GradientType=0 );
                z-index: 3;
                color: $dark-gray;
                display: block;

                .close-tab-content {
                    @include font-title;
                    position: absolute;
                    display: block;
                    top: 4px;
                    right: 13px;
                    /* content: "\00D7"; */
                    font-size: 35px;
                    font-weight: 100;
                    color: $brand-color-red;
                    text-align: center;
                    line-height: 1;
                    text-decoration: none;

                    @include media(bp-lt-lg) {
                        width: 42px;
                        height: 42px;
                        font-size: 42px;
                        left: 15px;
                        top: 5px;
                        text-align: left;

                        .desktop {
                            display: none;
                        }

                        .mobile {
                            display: block;
                        }
                    }
                }

                section {
                    background: transparent;

                    a {
                        color: $brand-color-red;
                    }

                    p {
                        color: $dark-gray;
                    }

                    .btn {
                        &.blue {
                            background: $tires-service-tab-content-btn-blue-color !important;
                            border: solid 2px #034e85 !important;
                            color: white;
                        }
                    }

                    form {
                        .btn {
                            &.blue {
                                background: #f8f8f8 !important;
                                color: #666666;
                                border: 2px solid #666666 !important;
                            }
                        }
                    }
                }

                @include media(bp-lt-lg) {
                    h3 {
                        padding-left: 20px;
                    }
                }
            }
        }

        .widget-tab-content-wrapper {
            a {
                &.default {
                    display: inline-block;
                    cursor: pointer;
                    width: 100%;
                }

                &.btn {
                    &.blue {
                        margin-bottom: 20px;
                    }
                }
            }

            .store {
                position: relative;
                color: $dark-gray;
                font-size: 18px;
                width: 100%;

                a {
                    text-decoration: none;
                }

                h4 {
                    font-size: 20px;
                    margin: 0 0 12px 0;
                }

                .has-store,
                .no-store {
                    margin-top: 0;
                    line-height: normal;
                }

                .has-store {
                    display: block;

                    .phone {
                        display: inline-block;
                        margin-top: 12px;
                        font-size: 20px;
                    }
                }

                .no-store {
                    display: none !important;
                }

                .phone,
                .store-directions {
                    @include font-title;
                    text-transform: uppercase;
                }

                .store-directions {
                    position: absolute;
                    top: 0;
                    right: 0;
                    max-width: 116px;
                    text-align: center;
                    font-size: 18px;

                    img {
                        margin-bottom: 8px;
                    }
                }

                .store-hours {
                    margin-bottom: 0;
                    font-size: 15px;

                    table {
                        width: 100%;
                    }

                    .holiday {
                        color: $brand-color-red !important;
                    }
                }

                .store-ctas {
                    display: table;
                    width: 100%;
                    margin: 20px 0;

                    span {
                        display: table-cell;
                        width: 50%;
                        padding: 0 0 0 15px;

                        &:first-child {
                            padding: 0;
                        }
                    }

                    .btn {
                        border: 1px solid $brand-color-red !important;
                        background: transparent;
                        font-size: 17px;
                    }
                }
            }
        }

        .icon-phone {
            @include icon('phone-grid', $brand-color-red, 16px);
            margin: -2px 4px 0 0;
        }

        .has-local-store & {
            .has-store {
                display: block !important;
            }

            .no-store {
                display: none !important;
            }

            .store-ctas {
                .has-store {
                    display: table-cell !important;
                }
            }
        }

        .no-local-store & {
            // .set-zip-form {
            //     display: none !important;
            // }

            .has-store {
                display: none !important;
            }

            .no-store {
                display: block !important;
            }

            .widget-tab-content {
                display: block;
            }

            .store-location {
                .no-store {
                    display: block !important;
                }
            }

            .store-directions {
                display: none;
            }

            .store-hours {
                display: none;
            }

            .store-ctas {
                margin-bottom: 10px !important;

                .no-store {
                    display: table-cell !important;
                    padding: 0 !important;

                    a {
                        margin-bottom: 0;
                    }
                }
            }
        }

        @include media(bp-lt-md) {
            height: 522px;
        }
    }
}
