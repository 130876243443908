@charset "UTF-8";
/* guide to breakpoints

    <------XS------|a|------SM------|b|------MD------|c|------LG------|d|------XL------|e|----XXL----->
    <----LT-SM-----|a|
    <----LT-MD----------------------|b|
    <----LT-LG---------------------------------------|c|
    <----LT-XL--------------------------------------------------------|d|
    <----LT-XXL------------------------------------------------------------------------|e|
                   |a|------GT-XS--------------------------------------------------------------------->
                                    |b|------GT-SM---------------------------------------------------->
                                                     |c|------GT-MD----------------------------------->
                                                                      |d|------GT-LG------------------>
                                                                                       |e|---GT-XL---->
*/
.font-body {
  font-family: "Avenir", Arial, sans-serif !important;
  font-size: 16px;
  line-height: 110%;
}

.overview {
  background: linear-gradient(to bottom, #ebebeb, #fff 60px);
}
.overview .title {
  font-family: "Ultramagnetic", Arial, sans-serif !important;
  font-size: 42px;
  font-weight: normal;
  color: #4a4a4a;
}
.overview .item-wrapper {
  border-bottom: 1px solid #dcdcdc;
}
.overview .item {
  font-size: 16px;
}
.overview .item ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.overview .item ul li {
  padding: 0 0 0.2em 1em;
  text-indent: -1.25em;
  line-height: 1.2em;
  color: #4a4a4a;
}
.overview .item ul li:before {
  content: "•";
  padding-right: 0.2em;
  font-size: 1.5em;
  vertical-align: top;
  color: #d81e05;
}
@media only screen and (max-width: 767px) {
  .overview .item ul li {
    padding-left: 0.7em;
    text-indent: -0.85em;
    font-size: 15px;
  }
  .overview .item ul li:before {
    font-size: 1em;
  }
}
.overview .item-title {
  font-family: "Ultramagnetic", Arial, sans-serif !important;
  font-size: 36px;
  color: #4a4a4a;
}
.overview p {
  color: #4a4a4a;
}
.overview .overview-body {
  font-size: 14px;
  color: #4a4a4a;
}
.overview .overview-body b,
.overview .overview-body strong {
  font-family: "Ultramagnetic", Arial, sans-serif !important;
  font-size: 18px;
}
@media only screen and (max-width: 767px) {
  .overview .title {
    font-size: 30px;
  }
  .overview .item,
  .overview .item-wrapper {
    border-bottom: none;
  }
  .overview .item-title {
    font-size: 24px;
  }
}

.overview-carousel .item-wrapper:last-of-type {
  border-bottom: none;
}

.overview-price-item .cta-link {
  font-family: "Ultramagnetic", Arial, sans-serif !important;
  font-size: 16px;
  color: #4a4a4a;
  text-decoration: none;
}
.overview-price-item .cta-link:after {
  content: ">";
  margin-left: 6px;
  color: #d81e05;
}
.overview-price-item .cta-link:hover {
  text-decoration: none;
  color: #d81e05;
}
@media only screen and (max-width: 767px) {
  .overview-price-item .cta-link {
    font-size: 16px;
  }
}
.overview-price-item .description {
  border-right: 1px solid #dcdcdc;
}
@media only screen and (max-width: 539px) {
  .overview-price-item .description .btn {
    margin-top: 10px;
  }
}
.overview-price-item .internet-price .label {
  font-size: 12px;
  color: #666;
}
.overview-price-item .internet-price .price {
  font-family: "Ultramagnetic", Arial, sans-serif !important;
  font-size: 60px;
  color: #d81e05;
}
.overview-price-item .internet-price .price span {
  vertical-align: top;
  font-size: 20px;
}
.overview-price-item .folder {
  font-family: "Ultramagnetic", Arial, sans-serif !important;
  font-size: 18px;
}
.overview-price-item .folder:after, .overview-price-item .folder.active:after {
  font-family: "Avenir", Arial, sans-serif !important;
  font-size: 24px;
}
.overview-price-item .folder.active:after {
  margin-top: -1px;
  font-size: 22px;
}
@media only screen and (max-width: 1024px) {
  .overview-price-item .internet-price .label {
    font-size: 11px;
  }
  .overview-price-item .internet-price .price {
    font-size: 40px;
  }
  .overview-price-item .internet-price .price span {
    font-size: 18px;
  }
}
@media only screen and (max-width: 767px) {
  .overview-price-item .description {
    border-right: none;
    border-bottom: 1px solid #dcdcdc;
  }
  .overview-price-item .internet-price {
    border-bottom: 1px solid #dcdcdc;
  }
  .overview-price-item .internet-price .label {
    font-size: 13px;
  }
  .overview-price-item .internet-price .price {
    font-size: 80px;
  }
  .overview-price-item .internet-price .price span {
    font-size: 24px;
  }
}

.overview-thumbnail-item .item-subtitle {
  font-family: "AvenirHeavy", Arial, sans-serif !important;
  color: #4a4a4a;
}
@media only screen and (max-width: 767px) {
  .overview-thumbnail-item .item-subtitle {
    color: #d81e05;
  }
}

.overview-basic-item .item-supertitle {
  font-family: "Ultramagnetic", Arial, sans-serif !important;
  font-size: 16px;
  color: #808080;
}
@media only screen and (max-width: 767px) {
  .overview-basic-item .item-supertitle {
    font-size: 18px;
  }
}