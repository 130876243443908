@import '~fcac/scss/shared/shared';

.product-results {
    aside {
        .title {
            @include font-title;
            color: $text-color;
        }
    }

    .accordion {
        .label,
        .folder {
            @include font-title;
            color: $text-color;
        }
    }

    .filter-btn a {
        @include font-title;
        color: $brand-color-red;

        &:after {
            @include icon(sliders, $brand-color-red, 32px);
            top: -4px;
            margin-left: -4px;
            height: 32px;
            -webkit-transform: scale(0.5);
            transform: scale(0.5);
        }
    }

    .filter-toolbar {
        .close-btn {
            @include font-title;
            color: $text-color;

            &:after {
                @include font-title;
                border: 1px solid $brand-color-red;
                color: $brand-color-red;
            }
        }
    }

    .number-of-results {
        @include font-title;
        color: $text-color;
    }

    .sort {
        label {
            color: $text-color;
        }
    }

    .product {
        &.inactive {
            .tire-quote-btn {
                cursor: default;
                pointer-events: none;

                &:hover {
                    background-color: $brand-color-red;
                }
            }

            .quote-container {
                -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=25)';
                filter: alpha(opacity=25);
                opacity: 0.25;
            }
        }
    }

    .rating {
        background-color: $brand-color-blue;
        color: $white;

        &:before {
            border-top: 13px solid transparent;
            border-right: 13px solid $white;
            border-bottom: 13px solid transparent;
            border-left: 13px solid transparent;
        }

        @include media(bp-lt-md) {
            &:before {
                border-top: 8px solid $brand-color-blue;
            }
        }
    }

    .compare-checkbox {
        label {
            color: $text-color;
        }
    }

    .copy-container {
        .coupon a,
        .rebate a {
            margin-left: 22px;

            &:before {
                @include icon(coupon, $brand-color-red, 32px);
                top: -7px;
                left: -30px;
                -webkit-transform: scale(0.6);
                transform: scale(0.6);
            }
        }

        .price {
            @include font-body;

            span {
                @include font-title;
                color: $text-color;
            }
        }
    }

    .product-name {
        @include font-title;
        color: $brand-color-red;
    }

    .product-id {
        @include font-body-bold;

        color: $text-color;
    }

    .quote-container {
        .coupon > span,
        .rebate > span {
            &:before {
                @include icon(coupon, $brand-color-blue);
            }
        }
    }

    .price {
        @include font-title;

        color: $text-color;
        font-size: 16px;

        span {
            color: $brand-color-red;
            font-size: 46px;
        }
    }

    .disclaimer {
        color: $text-color;
        font-size: 14px;

        @include media(bp-lt-md) {
            font-size: 9px;
        }
    }

    .credit-promotion {
        @include media(bp-lt-md) {
            @include gradient-top();
        }
    }

    .credit-promotion-title {
        @include font-body-bold;

        color: $text-color;
        font-size: 14px;

        @include media(bp-lt-md) {
            @include font-title;

            font-size: 30px;
        }
    }

    // GUARANTEE
    .guarantee-carousel {
        @include media(bp-gt-sm) {
            .title {
                @include font-body;

                color: $brand-color-blue;
                font-size: 22px;

                span {
                    @include font-title;

                    font-size: 40px;
                    text-transform: uppercase;
                }
            }
        }

        @include media(bp-lt-md) {
            @include gradient-top();

            .title {
                font-size: 30px;
            }
        }
    }

    .gbb {
        .card::before {
            @include font-title;
        }
    }
}
