@import '~fcac/scss/shared/shared';

.thirty-seventy {
    &.bg-gray-gradient {
        @include gradient-bottom();
    }
    &.bg-none {
        background: none;
        background-color: transparent;
    }
    &.bg-color {
        background: none;
        background-color: $bg-color;
    }

    .title {
        @include font-title;

        font-size: 42px;
        text-transform: uppercase;
        color: $text-color;
        font-weight: normal;

        @include media(bp-lt-md) {
            font-size: 30px;
        }
    }

    .subtitle {
        @include font-title;

        font-size: 18px;
        font-weight: normal;
        color: $text-color;
    }
}
