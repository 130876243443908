.intro-article {
    .title,
    .subtitle {
        @include font-title;
    }
}

.articles {
    a {
        @include font-title;
        color: $dark-gray;
        text-decoration: none;

        span {
            &:last-child {
                color: $brand-color-red;
            }
        }
    }

    a:hover {
        color: $brand-color-red;
    }

    .summary {
        p {
            font-size: 14px;
        }
    }

    @include media(bp-lt-sm) {
        .intro-cta {
            a {
                background-color: #666;
            }

            span {
                display: none;
            }
        }

        padding-bottom: 0 !important;
    }
}

.slide-content {
    p {
        font-size: 14px;
    }
}

.article-body {
    p {
        img {
            padding-left: 25px;
        }
    }
}

.articles.reskin {
    background: none;
    padding: 40px 0;

    h2,
    h3 {
        margin: 5px;
        text-align: center;
    }
    h3.subtitle {
        @include font-body;
        font-size: 20px;
        padding: 0 15%;
    }
    h2 {
        @include media(bp-lt-sm) {
            font-size: 28px;
            font-size: 7vw;
        }
    }

    .col {
        width: 33%;
        border-right: none;

        @include media(bp-lt-sm) {
            &:last-child {
                display: block;
            }
        }
    }
    .articles-wrap {
        margin: 30px 0;
        overflow: initial;
        @include media(bp-lt-lg) {
            margin: 30px 0 60px;
        }
        @include media(bp-lt-sm) {
            margin: 20px 0 60px;
        }
    }

    .teaser .image {
        height: auto;
        max-height: none;

        img {
            height: auto;
            max-width: none;
            width: 100%;
        }
    }

    @include media(bp-lt-sm) {
        .teaser .image {
            height: auto;
            max-height: 55vw;
        }
    }

    .teaser .title {
        font-size: 18px;
        @include media(bp-lt-sm) {
            font-size: 14px;
            text-align: center;
            width: 100%;
        }
    }
}
