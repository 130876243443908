/* guide to breakpoints

    <------XS------|a|------SM------|b|------MD------|c|------LG------|d|------XL------|e|----XXL----->
    <----LT-SM-----|a|
    <----LT-MD----------------------|b|
    <----LT-LG---------------------------------------|c|
    <----LT-XL--------------------------------------------------------|d|
    <----LT-XXL------------------------------------------------------------------------|e|
                   |a|------GT-XS--------------------------------------------------------------------->
                                    |b|------GT-SM---------------------------------------------------->
                                                     |c|------GT-MD----------------------------------->
                                                                      |d|------GT-LG------------------>
                                                                                       |e|---GT-XL---->
*/
.font-body {
  font-family: "Avenir", Arial, sans-serif !important;
  font-size: 16px;
  line-height: 110%;
}

.offers .heading {
  font-family: "Ultramagnetic", Arial, sans-serif !important;
  font-size: 32px;
}
.offers .offers-signup {
  padding: 20px 0;
}
@media only screen and (max-width: 539px) {
  .offers .offers-signup {
    padding: 0 0 20px;
  }
  .offers .offers-signup .title {
    font-family: "Ultramagnetic", Arial, sans-serif !important;
    font-size: 26px !important;
    color: #000;
  }
}
.offers .offers-signup .title {
  font-family: "Ultramagnetic", Arial, sans-serif !important;
  font-size: 18px;
  line-height: 1.8;
}
@media only screen and (max-width: 1024px) {
  .offers .offers-signup .title {
    font-size: 16px;
  }
}
@media only screen and (max-width: 539px) {
  .offers .offers-signup .title {
    font-size: 18px;
  }
}
.offers .offers-signup .btn {
  border: 0;
  padding: 5px 9px;
  line-height: 1;
  vertical-align: middle;
  background-color: #333;
}
.offers .offers-signup .ctas {
  line-height: 2.2;
}
.offers .offer {
  background: #fafafa;
}
.offers .offer .title {
  font-family: "Ultramagnetic", Arial, sans-serif !important;
  text-transform: uppercase;
  font-size: 26px;
  line-height: 1;
}
@media only screen and (max-width: 1024px) {
  .offers .offer .title {
    font-size: 20px;
  }
}
.offers .offer .expiration {
  font-family: "AvenirHeavy", Arial, sans-serif !important;
  font-size: 12px;
}
.offers .offer .coupon-cta .btn,
.offers .offer .coupon-text-cta .btn {
  padding: 8px 12px;
  font-size: 18px;
}
.offers .offers-area .heading {
  font-size: 26px;
  margin-bottom: 0;
}
@media only screen and (max-width: 539px) {
  .offers .offers-area .heading {
    margin-bottom: 15px;
  }
}
.offers .offers-area .desc {
  margin: 2px 0 20px;
}
@media only screen and (max-width: 539px) {
  .offers .offers-area .desc {
    display: none;
  }
}
@media only screen and (max-width: 1024px) {
  .offers .offers-area .btn {
    font-size: 15px;
  }
}
.offers .offers-more .btn {
  background-color: transparent;
  color: #333;
  padding: 0;
  border: 0;
}
.offers .offers-more .btn:after {
  content: ">";
  color: #d81e05;
  margin-left: 7px;
}