.vehicle-info-bar {
    .vehicle {
        @include font-title;

        @include media(bp-lt-md) {
            width: 100%;
            padding-top: 5px;
        }
    }
}
