// .default is used to show original styles and .no-styles is an option to hide styles
.cookie-consent-container:not(.default):not(.no-styles) {
    @include font-body-bold;

    .cookie-consent-wrapper {
        .cc-submit__btn {
            @include font-body-bold;
            background-color: $brand-color-red;

            &:hover {
                background-color: darken($brand-color-red, 10%);
            }
        }
    }
}
