.prompts {
    width: 390px;

    .red-copy {
        color: #d21b0a;
    }

    .black-copy {
        color: black;
    }

    &.newly-migrated-user-first-login {
        h3 {
            font-size: 17px !important;
            text-align: center;
            padding: 0 75px 0;
        }
    }
    .green-copy {
        color: #219653;
    }

    &.system-error {

    }

    #forgotPasswordForm {
        text-align: left;
    }
}