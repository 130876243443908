.overview-thumbnail-item {
    .item-subtitle {
        @include font-body-bold;
        color: $text-color;
    }

    @include media(bp-lt-md) {
        .item-subtitle {
            color: $brand-color-red;
        }
    }
}
