@import '~fcac/scss/shared/shared';

$border-color-grey: #dcdcdb;
$light-color-grey: #cccccc;
$inactive-text-color: #a5a5a5;

.contact-us {
    .wrap {
        background: linear-gradient(to top, #eee, transparent 150px);
        border: 1px solid $border-color-grey;
    }

    .details-col,
    .contact-col {
        @include media(bp-lt-sm) {
            background: linear-gradient(to bottom, #eee, transparent 50px);
        }

        .heading {
            font-size: 22px;

            @include media(bp-lt-sm) {
                margin-top: 40px;
                font-size: 17px;
                text-align: left;
            }
        }

        .sub-heading {
            clear: both;
            margin: 15px 0;
        }
    }

    .details-col {
        border-right: solid 1px $appt-step-border-color-light;

        @include media(bp-lt-sm) {
            border: none;
        }

        .icn-call-text {
            @include font-title;
            font-size: 26px;
            color: $brand-color-red;
            text-transform: uppercase;

            span {
                font-size: 16px;
                display: block;
            }
        }

        .inner-details {
            border-bottom: 1px solid $border-color-grey;
            padding-bottom: 15px;
        }

        .visit-us {
            margin-bottom: 10px;
        }
    }

    .contact-col {
        .fields {
            .contact-city-disable {
                .custom-select {
                    background-color: $light-color-grey;
                    select {
                        color: $inactive-text-color;
                    }
                }

                label {
                    color: $light-color-grey;
                }
            }

            .contact-select-store {
                width: 100%;
            }
        }
    }

    label {
        @include font-body-bold;
    }
}
