@import '~fcac/scss/shared/shared';

$accordion-bg-color: #ebebeb;
$accordion-border-color: #bebebe;
$accordion-title-color: #656565;

.accordion {
    &.mobile-only {
        @include media(bp-gt-sm) {
            .folder {
                padding: 0;
                border-bottom: none;
                background-color: transparent;
                color: $text-color;
                text-transform: none;

                &.active {
                    border-bottom: none;
                    background-color: transparent;
                    color: $text-color;
                }
            }
        }
    }

    .folder {
        padding: 24px 15px;
        border-bottom: 1px solid $accordion-border-color;
        background-color: $accordion-bg-color;
        color: $accordion-title-color;
        text-transform: uppercase;

        &:after {
            color: $brand-color-red;
        }

        &.active {
            border-bottom: none;
            background-color: $white;
            color: $text-color;
        }
    }

    .item {
        transition: padding .1s ease-out, opacity .1s ease-out, max-height .2s ease-out;

        &.active {
            transition: padding .2s ease-in, opacity .2s ease-in, max-height .5s ease-in;
        }
    }
}
