/* guide to breakpoints

    <------XS------|a|------SM------|b|------MD------|c|------LG------|d|------XL------|e|----XXL----->
    <----LT-SM-----|a|
    <----LT-MD----------------------|b|
    <----LT-LG---------------------------------------|c|
    <----LT-XL--------------------------------------------------------|d|
    <----LT-XXL------------------------------------------------------------------------|e|
                   |a|------GT-XS--------------------------------------------------------------------->
                                    |b|------GT-SM---------------------------------------------------->
                                                     |c|------GT-MD----------------------------------->
                                                                      |d|------GT-LG------------------>
                                                                                       |e|---GT-XL---->
*/
.font-body {
  font-family: "Avenir", Arial, sans-serif !important;
  font-size: 16px;
  line-height: 110%;
}

.vehicle-info-bar .vehicle {
  font-family: "Ultramagnetic", Arial, sans-serif !important;
}
@media only screen and (max-width: 767px) {
  .vehicle-info-bar .vehicle {
    width: 100%;
    padding-top: 5px;
  }
}