//@charset 'UTF-8';
/* Slider */
.slick-loading .slick-list {
    // background: $white url('./ajax-loader.gif') center center no-repeat;
}

/* Icons */
@font-face {
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;

    // src: url('./fonts/slick.eot');
    // src: url('./fonts/slick.eot?#iefix') format('embedded-opentype'), url('./fonts/slick.woff') format('woff'), url('./fonts/slick.ttf') format('truetype'), url('./fonts/slick.svg#slick') format('svg');
}
/* Arrows */
.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 30px;
    height: 30px;
    padding: 0;
    margin-top: -10px\9; /*lte IE 8*/
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: 0.75;
    color: $black; //white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
    left: -25px;
}
[dir='rtl'] .slick-prev {
    right: -25px;
    left: auto;
}
.slick-prev:before {
    @include font-title;
    font-size: 36px;
    font-weight: normal;
}
[dir='rtl'] .slick-prev:before {
    @include font-title;
    font-size: 36px;
    font-weight: normal;
}

.slick-next {
    right: -25px;
}
[dir='rtl'] .slick-next {
    right: auto;
    left: -25px;
}
.slick-next:before {
    @include font-title;
    font-size: 36px;
    font-weight: normal;
}
[dir='rtl'] .slick-next:before {
    @include font-title;
    font-size: 36px;
    font-weight: normal;
}

/* Dots */
.slick-dots {
    position: absolute;
    bottom: -45px;

    display: block;

    width: 100%;
    padding: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li {
    position: relative;

    display: inline-block;

    width: 12px;
    height: 12px;
    margin: 0 3px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button {
    font-size: 0;
    line-height: 0;

    display: block;

    width: 12px;
    height: 12px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
    opacity: 1;
}
.slick-dots li button:before {
    font-family: 'slick';
    font-size: 12px;
    line-height: 12px;

    width: 12px;
    height: 12px;

    opacity: 1;
    color: $white;
    border: 1px solid #9b9b9b;
    border-radius: 6px;
    background-color: $white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
    opacity: 1;
    color: #c40000;
    background-color: #c40000;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    color: $black;
}

.swiper-pagination-bullet {
    &.swiper-pagination-bullet-active {
        color: #c40000;
        background-color: #c40000 !important;
    }
}
