@import '~fcac/scss/shared/shared';

.about-us {
    overflow: hidden;
}

.about-us .image-wrap {
    img {
        max-width: none;
    }
}

.about-us .copy {
    .headline,
    .subhead {
        @include font-title;
    }

    .subhead {
        letter-spacing: -1px;
    }

    .subhead br {
        display: none;
    }

    .description {
        @include font-body;
        width: 70.83%;
    }

    .logo {
        border: 0;
    }

    p {
        @include media(bp-lt-sm) {
            font-size: 14px;
        }
    }
}

.about-us.reskin {
    overflow: visible;

    .quote {
        .bubble {
            background: $brand-color-red;
            color: $white;
            width: 50%;

            > p {
                @include font-body;
                font-size: 27px;
                padding: 32px;

                small {
                    font-size: 16px;
                }
            }

            &:after {
                border-color: $brand-color-red transparent;
            }
        }
    }

    .copy {
        width: 60%;

        h3 {
            @include font-title;
            font-size: 24px;
            color: #666;
            margin: 0 0 19px 0;
        }

        hr {
            border-top: 1px solid $brand-color-red;
            margin: 0 auto 20px auto;
        }

        p {
            @include font-body;
            font-size: 16px;
            color: #666;
            margin-top: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    @media only screen and (max-width: 1300px) {
        .quote {
            .bubble {
                width: 65%;
            }
        }

        .copy {
            width: 75%;
        }
    }

    @media only screen and (max-width: 1024px) {
        .quote {
            .bubble {
                width: 75%;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        padding: 0 4.86111%;

        .quote {
            margin-bottom: 52px;

            .bubble {
                top: 0;
                width: 100%;

                > p {
                    font-size: 27px;
                    padding: 32px;
                }
            }
        }

        .copy {
            width: 90%;

            .heading {
                h3 {
                    margin-top: 28px;
                }

                hr {
                    width: 100%;
                    margin: 0 auto;
                }
            }
        }
    }
}

@media only screen and (min-width: 320px) {
    .about-us .image-wrap {
        text-indent: -525px;
    }

    .about-us .copy {
        .subhead {
            font-size: 24px;
        }

        .description {
            font-size: 15px;
            width: 100%;
            padding: 0 15px 20px;

            p.last-child {
                display: none;
            }
        }

        .description br {
            display: block;
        }

        .logo {
            width: 47px;
            left: 45%;
            bottom: -30px;
        }
    }
}

@media only screen and (min-width: 400px) {
    .about-us .copy {
        .subhead {
            font-size: 28px;
        }

        .description {
            font-size: 16px;
        }
    }
}

@media only screen and (min-width: 450px) {
    .about-us .copy {
        .subhead {
            font-size: 30px;
        }

        .description {
            font-size: 17px;
        }
    }
}

@media only screen and (min-width: 575px) {
    .about-us .image-wrap {
        text-indent: -450px;
    }

    .about-us .copy {
        .subhead {
            font-size: 34px;
        }
    }
}

@media only screen and (min-width: 640px) {
    .about-us .image-wrap {
        text-indent: -450px;
    }
}

@media only screen and (min-width: 750px) {
    .about-us .image-wrap {
        text-indent: -350px;
    }

    .about-us .copy {
        .description br {
            display: none;
        }

        .description p.last-child {
            display: block;
        }
    }
}

@media only screen and (min-width: 875px) {
    .about-us .image-wrap {
        text-indent: -275px;
    }

    .about-us .copy {
        .subhead {
            font-size: 40px;
        }

        .description {
            font-size: 18px;
        }
    }
}

@media only screen and (min-width: 960px) {
    .about-us .copy {
        .description {
            width: 95%;
        }
    }
}

@media only screen and (min-width: 1024px) {
    .about-us .copy {
        .subhead {
            font-size: 44px;
        }

        .description {
            padding: 0 15px 30px;
        }

        .logo {
            width: 60px;
            left: initial;
            bottom: -30px;
        }
    }
}

@media only screen and (min-width: 1100px) {
    .about-us .image-wrap {
        text-indent: -175px;
    }

    .about-us .copy {
        .subhead {
            font-size: 48px;
        }
    }
}

@media only screen and (min-width: 1200px) {
    .about-us .copy {
        .description {
            width: 90%;
        }
    }
}

@media only screen and (min-width: 1275px) {
    .about-us .image-wrap {
        text-indent: -100px;
    }

    .about-us .copy {
        .description {
            width: 85%;
        }
    }
}

@media only screen and (min-width: 1350px) {
    .about-us .image-wrap {
        text-indent: 0;
    }

    .about-us .copy {
        .description {
            width: 80%;
        }
    }
}

@media only screen and (min-width: 1400px) {
    .about-us .image-wrap {
        text-indent: 0;
    }

    .about-us .copy {
        .description {
            width: 75%;
            padding: 0 15px 30px;
        }

        .logo {
            width: auto;
            left: initial;
            bottom: -40px;
        }
    }
}
