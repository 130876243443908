@import '~fcac/scss/shared/shared';

.local-store {
    .local-store-content {
        .heading,
        .phone,
        .open,
        .ctas {
            @include font-title;
        }

        .heading,
        .phone {
            text-transform: uppercase;
        }

        .phone {
            color: $brand-color-red;
        }

        .cta-dir,
        .cta-call {
            color: $brand-color-red;
            text-transform: uppercase;
        }

        .cta-call {
            &:before {
                @include icon('phone', $brand-color-red);
            }
        }

        .hours {
            span {
                @include font-body-bold;
            }
        }

        .holiday {
            color: $brand-color-red;
        }
    }

    &.reskin {
        background-color: #fafafa;
        @include gradient-bottom();

        .cta-store-bar {
            .store-info {
                .store-info-toggle {
                    .store {
                        strong {
                            @include font-title;
                        }
                    }
                }
            }
        }

        .close {
            @include font-title;
        }

        .inner {
            .store-ctas {
                a {
                    @include media(bp-lt-md) {
                        @include font-title;
                    }
                }
            }

            .mobile-ctas {
                a {
                    @include font-title;
                }
            }

            .directions-cta {
                .directions {
                    color: $dark-gray;
                    @include font-title;
                }
            }
        }
    }
}
