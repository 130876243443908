@import '~fcac/scss/shared/shared';

.hero {
    .copy {
        color: $white;

        h1,
        h2,
        h3,
        .headline {
            color: $white;
        }

        .subhead {
            color: $white;
        }
    }

    &.hero-widget.promo-flag {
        .offer-container.offer-template .price,
        .offer-container.offer-template .title {
            color: $brand-color-red;
        }
    }
}

.promo-flag-form.mini-schedule-appt {
    background-color: $dark-gray;

    h3 {
        color: $white;
    }

    a.btn {
        color: $white;
        background-color: transparent;
        padding: 0;

        &:after {
            content: '\003e';
            color: $brand-color-red;
            margin-left: 7px;
        }
    }
}
