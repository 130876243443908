@import '~fcac/scss/shared/shared';

.content-carousel {
    svg {
        fill: $brand-color-red;
    }

    .title {
        @include font-title;

        font-size: 42px;
        text-transform: uppercase;
        color: $text-color;
        font-weight: normal;
    }

    .slide {
        p {
            margin: 0.5em 0;
            font-size: 16px;
            color: $text-color;
            line-height: 1.2em;
        }
    }

    .slide-title {
        margin: 0 0 0.25em;
        font-size: 18px;
        color: $text-color;
    }

    @include media(bp-lt-md) {
        .title {
            font-size: 30px;
            line-height: 1em;
            text-align: center;
        }
    }

    &.reskin {
        background: #ebebeb;
        padding: 50px 0 0;

        .container {
            background: $white;
            padding: 5% 0;

            .content-log {
                width: 50px;
                display: block;
                margin: -8% auto 2%;
            }

            @include media(bp-lt-md) {
                background: #ebebeb;
            }
        }

        a {
            @include font-title;
            text-transform: uppercase;
            text-decoration: none;
            font-size: 16px;
            text-align: center;
            margin: 0 auto;
            display: block;

            span {
                color: $dark-gray;
            }
        }

        .title {
            font-size: 30px;
            line-height: 1.4;
        }

        .slide {
            p {
                margin: 0;
                line-height: 1.4em;
            }
        }

        .slide-content {
            p {
                width: 82%;
                margin: 20px auto;
            }
        }

        .slide-title {
            line-height: 1.2em;
        }

        @include media(bp-lt-md) {
            padding: 30px 0 0;
        }
    }
}
