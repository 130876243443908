#get-vehicle-modal,
#will-this-fit {
    &.coral-Modal {
        max-width: 450px;
        width: auto;
        height: auto;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .coral-Modal-header {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
    }
}

#get-vehicle-modal .refresh-modal__close {
    top: 10px;
    right: 10px;
}

#chat-contact {
    .chat-session {
        height: 100%;

        iframe {
            width: 100%;
            height: 100%;
        }
    }
}
