h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    @include font-title;
    font-weight: normal;
    text-transform: uppercase;
    line-height: 1.1;
}

b,
strong {
    @include font-body-bold;
    font-weight: normal;
}
