@import '~common/scss/shared/shared';

.rm-open-body .rm-modal,
.my-account {
    .form-actions {
        align-items: baseline;
        display: flex;
        justify-content: center;
        margin-top: 25px;
        > div {
            text-align: center;
            flex-grow: 1;
            margin-bottom: 10px;
            padding-left: 10px;
            padding-right: 10px;
            button {
                width: 100%;
            }
        }

        @include media (bp-lt-md) {
            flex-direction: column-reverse;
            width: 100%;
            button {
                width: 100%;
                &.btn.secondary {
                    margin-left: 0;
                    margin-top: 12px;
                }
            }
            > div {
                width: 100%;
            }
        }
        @include media(bp-gt-sm) {
            //max-width: 75%;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

