@import '~common/scss/shared/shared';

.four-column-content {
    _:-ms-lang(x),
    .slide-content {
        display: inline-block !important;
    }

    .title {
        text-align: center;
    }

    > .container {
        display: flex;
        flex-direction: column;
        margin-top: 25px;
    }

    .children {
        @include clearfix;
    }

    .component {
        display: inline-block;
        width: 23%;
        margin: 1%;
        vertical-align: top;
        float: left;

        .container {
            margin: 0;
            text-align: center;

            > div,
            .children {
                @include clearfix;
            }
        }

        .title-text-cta {
            background: #ebebeb;
            margin: 0;
            width: 100%;
            display: inline-block;
            border: 1px solid #ccc;

            > .container {
                display: block;
                padding: 0;
            }

            .image img {
                width: 100%;
            }
            h2,
            h3 {
                font-size: 22px;
                font-size: 2vw;

                @media screen and (min-width: 1600px) {
                    font-size: 35px !important;
                }
            }
            p {
                padding: 0 10px;
                height: 3.5em;
            }
            .cta {
                margin-bottom: 20px;
            }
        }
    }

    @include media(bp-lt-lg) {
        .component {
            display: inline-block;
            width: 48%;
            margin: 1%;

            .title-text-cta {
                h2,
                h3 {
                    margin: 0;
                }

                p {
                    padding: 0px 10px;
                    margin: 10px;
                    font-size: 14px;
                    line-height: 1.1em;
                }

                .image {
                    width: 40%;
                    float: left;
                    position: relative;
                    overflow: hidden;
                    top: 0;
                    bottom: 0;
                    max-height: 140px;
                }

                .image img {
                    width: 200%;
                    max-width: none;
                }

                .copy {
                    float: right;
                    width: 60%;
                    padding: 10px;
                }

                .cta {
                    margin: 0;
                }
            }
        }
    }

    @include media(bp-lt-md) {
        background-color: #ebebeb;
        margin: 4%;

        .body {
            order: 2;

            .cta-link {
                font-size: 16px;
                font-size: 3vw;
            }
        }
        .children {
            order: 1;
        }

        .component {
            display: block;
            width: 100%;
            float: none;

            .title-text-cta {
                background: $white;

                .subtitle {
                    font-size: 20px;
                    font-size: 4vw;
                    margin: 0;
                    color: $brand-color-red;
                }

                p {
                    padding: 0;
                    margin: 6px;
                }

                .cta-link {
                    font-size: 14px;
                    color: $brand-color-red;
                }

                .image img {
                    width: 160%;
                    max-width: none;
                }
            }
        }
    }
}
