@import '~fcac/scss/shared/shared';

.offers-signup {
    @include media(bp-lt-sm) {
        .offers-signup-email {
            font-size: 14px !important;
            padding: 10px !important;
            border-radius: 0 !important;
        }
        input{
            padding: 10px !important;
            font-size: 14px !important;
        }

        button.btn {
            padding: 11px 12px 11px 12px !important;
        }
    }
    // This css added because .btn related css is overwriting offers-refresh signup button css.
    &__btn{
        padding: 12px 16px 13px !important;
        vertical-align: unset !important;
        line-height: 1.2 !important;
    }
}