.custom-select {
    @include icon('select-arrow', #951200, 16px);
}

.custom-checkbox {
    input[type='checkbox'] {
        &:checked + label:before {
            @include icon('checkmark', #951200, auto);
        }
    }

    label {
        &.active::before {
            @include icon('checkmark', #951200, auto);
        }

        &:before {
            border: 2px solid #808080;
            background-color: $white;
        }
    }
}

.custom-radio {
    input[type='radio'] {
        &:checked + label:before {
            @include icon('radio-checked', $text-color, 16px);
        }
    }

    label {
        &:before {
            border: 2px solid $dark-gray;
            background-color: $white;
        }
    }
}
