@import '~fcac/scss/shared/shared';

$colorHighlight: #fbfad6;
$colorOddBG: #f1f1f1;
$colorLightDivider: #d0d0d0;
$colorMediumDivider: #dcdcdc;
$colorDarkDivider: #6a6a6a;
$colorRed: #ee3d42;
$colorTabBG: #ebebeb;
$colorDarkGray: $dark-gray;

// base selector is more specific because needs to override styles from base tabs.scss
.product-detail-tabs.tabs.tab-top {
    .tab-nav {
        border-top: 1px solid $colorLightDivider !important;
        border-bottom: 0 !important;

        ul {
            .active {
                background-color: $white !important;
            }
        }

        li {
            @include font-title;

            border-left: 1px solid $colorLightDivider !important;
            background-color: $colorTabBG;

            &:not(.active) {
                a {
                    @include gradient-bottom(10px, $colorMediumDivider);

                    &:hover {
                        color: $black;
                        background-color: darken($colorTabBG, 5%);
                    }
                }
            }
        }
    }

    .m-tab {
        background-color: $colorTabBG;
        border-bottom: 1px solid #bebebe;

        &:after {
            color: $brand-color-red;
        }
    }

    .tab-content {
        border-bottom: 1px solid $colorMediumDivider;
    }

    .border-bottom {
        border-bottom: 1px solid $colorMediumDivider;
    }

    .offers-area .heading {
        font-size: 18px;
    }

    .offer {
        .btn {
            font-size: 16px;
        }
    }

    table {
        .head {
            th {
                color: $colorRed;
            }
        }

        .header-dark {
            th {
                @include font-title;
                font-weight: normal;
                font-size: 16px;
                background-color: $colorDarkGray;
                color: $white;
                border-bottom: none;
            }
        }
    }

    tr {
        .odd {
            background-color: $colorOddBG;
        }
    }

    th,
    td {
        border-top: 1px solid $colorLightDivider;
        border-right: 1px solid $colorLightDivider;
        border-bottom: 1px solid $colorLightDivider;

        font-size: 14px;

        &:first-child {
            border-left: 1px solid $colorLightDivider;
        }
    }

    // table modifier class
    .table-detailed-tire-specs {
        tbody {
            th,
            td {
                @include font-body;
                font-weight: normal;
                font-size: 16px;
            }

            th {
                background-color: $colorHighlight;
            }

            tr:first-child {
                th,
                td {
                    border-top: none;
                }
            }
        }
    }

    // table modifier class
    .table-speed-rating {
        td {
            @include font-title;
            font-size: 30px;
            color: #808080;

            strong {
                @include font-body-bold;
                font-weight: normal;
                font-size: 16px;
                color: $colorDarkGray;
            }

            small {
                @include font-body;
                font-size: 12px;
                color: $colorDarkGray;
            }

            &.highlight {
                background-color: $colorHighlight;
            }
        }
    }

    .disclaimers .accordion .folder {
        color: #656565;
    }

    @include media(bp-lt-md) {
        section,
        .service {
            &:last-child {
                border-bottom: 1px solid $colorMediumDivider;
            }
        }
    }
}
