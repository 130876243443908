$internet-price-label-color: #666;

.overview {
    background: linear-gradient(to bottom, $overview-gradient-color, $white 60px);

    .title {
        @include font-title;
        font-size: 42px;
        font-weight: normal;
        color: $text-color;
    }

    .item-wrapper {
        border-bottom: 1px solid $overview-item-border-color;
    }

    .item {
        font-size: 16px;

        ul {
            @include bulleted-list();
        }
    }

    .item-title {
        @include font-title;
        font-size: 36px;
        color: $text-color;
    }

    p {
        color: $text-color;
    }

    .overview-body {
        font-size: 14px;
        color: $text-color;

        b,
        strong {
            @include font-title;
            font-size: 18px;
        }
    }

    @include media(bp-lt-md) {
        .title {
            font-size: 30px;
        }

        .item,
        .item-wrapper {
            border-bottom: none;
        }

        .item-title {
            font-size: 24px;
        }
    }
}

.overview-carousel {
    .item-wrapper {
        &:last-of-type {
            border-bottom: none;
        }
    }
}
