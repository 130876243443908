@import '~fcac/scss/shared/shared';

.battery-quote {
    .battery-col {
        .more {
            @include font-body-bold;

            a {
                text-decoration: none;
            }
        }
    }

    .total {
        .label,
        .amount {
            @include font-title;
        }

        .label {
            font-size: 26px;
            color: #666;
        }

        .amount {
            font-size: 38px;
            color: $brand-color-red;
        }

        .cents {
            display: inline-block;
            padding-top: 5px;
            font-size: 20px;
            vertical-align: top;
        }
    }

    .contact-col {
        @include media(bp-lt-sm) {
            background: linear-gradient(to bottom, #eee, transparent 50px);
        }

        .req {
            font-size: 12px;
        }
    }
    .custom-tooltip {
        .coral-Tooltip--positionRight {
            @include media(bp-lt-sm) {
                min-width: 15em;
            }
            min-width: 25em;
        }
    }
}
