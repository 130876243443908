@import '~fcac/scss/shared/shared';

.fifty-fifty-advanced-component {
    position: relative;

    > .container > .title {
        @include font-title;
        font-size: 30px;
        text-transform: none;
        margin: 0;
        @include media(bp-lt-md) {
            font-size: 20px;
        }
    }

    > .container > .subtitle {
        margin: 0 0 30px 0;

        @include media(bp-lt-md) {
            font-size: 18px;
        }
    }

    .gradient-bg {
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -2;
        &.bg-gray-gradient {
            @include gradient-bottom();
        }
        &.bg-none {
            background: none;
            background-color: transparent;
        }
        &.bg-color {
            background: none;
            background-color: $bg-color;
        }
    }
}

.fifty-fifty-advanced {
    .title {
        @include font-title;
        font-size: 24px;
        text-transform: uppercase;
    }

    @include media(bp-lt-md) {
        .title {
            font-size: 18px;
        }
    }
}

.fifty-fifty-advanced .reskin,
.fifty-fifty-advanced.reskin {
    > .col {
        width: 50%; // for some reason the orig 50/50 only used 49% wide columns
    }
    > .col:first-child {
        padding-right: 20px;
    }
    > .col:last-child {
        padding-left: 20px;
    }
    .col.cover {
        + col {
            width: 100%;
            p {
                width: 49%;
            }
        }
        width: 100%;
    }
    .col.cover + .col {
        .copy,
        h2,
        h3 {
            color: $white;
        }
    }

    h2,
    h3 {
        margin: 0;
        font-size: 20px;
        font-size: 2vw;
    }

    .copy {
        padding: 20px;

        p {
            width: 100%;
        }
    }

    // need a better way to target the main image
    .cover img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        width: 100%;
    }

    .floating-image {
        position: absolute;
        right: 0;
        width: 60%;
        margin-top: -20%;
        margin-right: -5%;
    }

    @include media(bp-lt-lg) {
        .copy p {
            width: 70%;
            line-height: 1.2em;
        }

        .floating-image {
            margin-top: -15%;
        }
    }

    @include media(bp-lt-md) {
        padding-bottom: 80px;

        > .col {
            width: 100%;
        }
        > .col:first-child {
            padding-right: 0;
        }
        > .col:last-child {
            padding-left: 0;
        }

        .copy {
            padding: 0;
            padding-top: 20px;
            height: 90vw;

            p {
                width: 100% !important;
                text-align: center !important;
            }

            h2,
            h3 {
                font-size: 20px;
                font-size: 5vw;
                text-align: center !important;
            }
        }

        .cover {
            overflow: hidden;

            img {
                width: 150%;
                margin-left: -25%;
                max-width: none;
            }
        }

        .floating-image {
            position: absolute;
            right: 0;
            left: 0;
            width: 100%;
            margin-top: 15%;
        }
    }
}
