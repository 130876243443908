@import '~fcac/scss/shared/shared';

.fun-fact {
    @include gradient-top();

    .title,
    p {
        @include font-title;
    }

    .title {
        font-size: 42px;
    }

    p {
        font-size: 24px;
        line-height: 2;
    }

    strong,
    span {
        font-size: 80px;
        color: $brand-color-red;
        line-height: 1;
    }

    @include media(bp-lt-md) {
        .title {
            font-size: 30px;
        }

        p {
            font-size: 12px;
            line-height: 14px;
        }

        strong,
        span {
            font-size: 40px;
            line-height: 40px;
        }
    }
}

#CQ span.highlight,
#CQrte span.highlight {
    font-size: 80px;
    color: $brand-color-red;
    line-height: 1;
}
