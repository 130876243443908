.articles-carousel {
    @include cta-link();

    .swiper-slide-active,
    .swiper-slide-next {
        .slide-content {
            border-right: 1px solid #dcdcdc;

            @media only screen and (max-width: 1024px) {
                border-right: 0;
            }
        }
    }

    .title {
        @include font-title;

        font-size: 42px;
        color: $text-color;
        font-weight: normal;
    }

    .slide-title {
        @include font-title;

        font-size: 24px;
        color: $text-color;
        font-weight: normal;

        a {
            text-decoration: none;
            color: $text-color;

            &:hover {
                text-decoration: none !important;
                color: $text-color !important;
            }
        }
    }

    @include media(bp-lt-md) {
        .title {
            font-size: 30px;
        }

        .slide-title {
            @include font-title;

            font-size: 24px;
            color: $text-color;
        }
    }
}
